import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Button, Form, Col, Input, Checkbox, Select, message } from 'antd'
import { getCode, forgotPassword } from '../redux/action/applicationAction'


const { Header, Content, Footer } = Layout

const FormItem = Form.Item
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
let countDownNum = 60
let countDownStr = ''
let timer

@connect(state => state)
class Forgot extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false,
            countDown: false,
            countDownStr: '',
            BizId: ''
        }

    }
    setTime = (countDownNum) => {
        countDownNum--
        countDownStr = countDownNum + 'S后重新获取'
        this.setState({
            countDownStr: countDownStr
        })
        timer = setTimeout(() => {
            this.setTime(countDownNum)
        }, 1000)
        if (countDownNum == 0) {
            this.setState({
                countDown: false
            })
            clearTimeout(timer)
        }

    }
    getForgotCode = (props) => {

        const { dispatch } = this.props
        const phoneNum = this.formRef.current.getFieldValue('userName')
        const TemplateCode = 'SMS_160230403'
        // console.log(phoneNum)
        if (phoneNum) {
            this.setState({
                countDown: true
            })
            const params = {
                phone: phoneNum,
                templateCode: TemplateCode
            }
            dispatch(getCode(params))
            .then(json => {
                if (json.success) {
                    this.setState({
                        BizId: json.data
                    })
                }
            })
            this.setTime(countDownNum)
        } else {
            message.error('请输入手机号码！')
        }
    }
    handleSubmit = values => {

        const { dispatch } = this.props
        values.bizId = this.state.BizId
        values.loginType = 1
        dispatch(forgotPassword(values))

    }
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    
    render() {
        const pwdRule = "^[A-Za-z0-9]{6,16}$"
        const phoneRule = "^[1][3,4,5,6,7,8,9][0-9]{9}$"
        const GetCodeButton = this.state.countDown ? (
            <Button type="primary" style={{ marginLeft: '10px', width: '120px' }}  disabled onClick={this.getForgotCode.bind(this, this.props)}>
                {this.state.countDownStr}
            </Button>
        ) : (
                <Button type="primary" style={{ marginLeft: '10px', width: '120px' }}   onClick={this.getForgotCode.bind(this, this.props)}>
                获取验证码
            </Button>
        )
        return (
            <Layout>
                <Header>
                    <div className="logo"></div>
                </Header>
                <Content>
                    <div className="forgot-content">
                        <Form
                            {...formItemLayout}
                            onFinish={this.handleSubmit}
                            className="forgot-form"
                            ref={this.formRef}
                        >
                            <h1>重置密码</h1>
                            <FormItem
                                label="手机号"
                                name="userName"
                                rules={[{ required: true, message: '请输入有效的手机号', pattern: new RegExp(phoneRule) }]}
                            >
                                <Input placeholder="请输入手机号" style={{ width: '280px' }} />
                            </FormItem>
                            <Form.Item label="验证码" className='addRequiredIcon'>
                                <Row >
                                    <Col >
                                        <Form.Item
                                            name="code"
                                            noStyle
                                            rules={[{ required: true, message: '请输入验证码', },]}
                                        >
                                            <Input placeholder="请输入验证码" style={{ width: '150px' }}  />
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        {GetCodeButton}
                                    </Col>
                                </Row>
                            </Form.Item>
                            <FormItem
                                label='新密码'
                                name="newPassword"
                                rules={[{ required: true, message: '请输入6-16位数字或字母', pattern: new RegExp(pwdRule) }]}
                            >
                                <Input.Password placeholder="请输入6-16位数字或字母" style={{ width: '280px' }} />
                            </FormItem>
                            <FormItem
                                label="确认新密码"
                                name="confirm"
                                dependencies={['newPassword']}
                                rules={[
                                    {
                                        required: true,
                                        message: '请再次输入新密码'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('密码两次输入不一致');
                                        }
                                    })
                                ]}
                            >
                                <Input.Password type="password" placeholder="请再次输入新密码" style={{ width: '280px' }} />
                            </FormItem>
                            
                            <FormItem {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" style={{ width: '180px' }} className="fogot-button">
                                    提交
							</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer>
            </Layout>
        )
    }
}

export default Forgot