import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Table, Card, Badge, Tabs, Form, Input, Select, Button, Space, message } from 'antd'
import moment from 'moment'
import { getPayHistory,  } from '../redux/action/applicationAction'

const patModeType = {
    0: '线上支付',
    1: '银行转账'
}

const checkStatusMap = {
    0: {
        text: '待审核', status: 'processing'
    },
    1: { text: '审核成功', status: 'success' },

    2: { text: '审核失败', status: 'error' }
}
const orderStatusMap = {
    10: '未付款',
    20: '已付款',
    30: '已退款',
    40: '已取消'
}


@connect(state => state)
class payHistory extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false
        }
    }
    componentDidMount() {
        this.fetchOrderList()
    }
    fetchOrderList() {
        const { dispatch } = this.props
        const { page, pageSize,  } = this.state
        const params = {
            pageNum: page,
            pageSize: pageSize,
            uid: this.props.loginReducer.userId,
        }
        this.setState({
            loading: true,
        })
        dispatch(getPayHistory(params))
            .then(json => {
                if (json.success) {
                    this.setState({
                        data: json.data.rows,
                        total: json.data.total,
                        loading: false
                    })
                }

            })
    }
    onChangePage = (page) => {
        this.setState({
            page: page
        }, () => {
            this.fetchOrderList()
        })
    }
 




    render() {
        const { data, page, pageSize, total,  loading } = this.state
        const paginationProps = {
            current: page,
            pageSize: pageSize,
            total: total,
            showTotal: (total, range) => `共 ${total} 条`,
            onChange: this.onChangePage
        }
        const columns = [
            {
                title: '订单编号',
                dataIndex: 'orderId'
            },
            
            {
                title: '创建时间',
                dataIndex: 'createTime',
                render: (text, record) => {
                    return <span>{ text ? moment(text).format('YYYY/MM/DD HH:mm:ss') : ''}</span>
                }
            },
            {
                title: '支付金额',
                dataIndex: 'payAmount'
            },
            {
                title: '支付方式',
                dataIndex: 'payMode',
                render: (text, record) => {
                    return <span>{patModeType[text]}</span>
                }
            },
            {
                title: '订单状态',
                dataIndex: 'orderStatus',
                render: (text, record) => {
                    return <span>{orderStatusMap[text]}</span>
                }
            },
            {
                title: '审核状态',
                dataIndex: 'checkStatus',
                render: (text, record) => {
                    return <Badge status={checkStatusMap[text].status} text={checkStatusMap[text].text} />
                }
            },

            {
                title: '审核信息',
                dataIndex: 'sellerMessage'
            },
            
        ]
        return (
            <div>
                <Card title='我的订单'>

                    
                    <Table
                        rowKey='orderId'
                        dataSource={data}
                        columns={columns}
                        pagination={paginationProps}
                        loading={loading}
                    />


                </Card>
                
            </div>
        )
    }
}

export default payHistory;
