import React, { Component } from 'react'
import {  Table,} from 'antd'


export default class ConfirmData extends Component {
    render() {
        if (!this.props.data) {
            return null
        }
        const { excelData, headerList } = this.props.data
        const dataSource = excelData.map(item => {
            const obj = Object.assign(item.excelData, { id: item.phone })
            return obj
        })

        let scrollWidth = 0
        const columns = headerList.map((item, index) => {
            const obj = {
                title: item,
                dataIndex: item,
                // width: 150,
                align: 'center',
                // ellipsis:true,
            }
            if (index === headerList.length - 1) {
                // delete obj['width']
            }
            // if (obj.width) {
            //     scrollWidth = scrollWidth + obj.width
            // }

            return obj
        })

        return (
            <div>
                <Table
                    columns={columns}
                    bordered
                    dataSource={dataSource}
                    rowKey={record => record.id}
                    scroll={{ x: true, y: 800 }}
                />
            </div>
        )


    }
}