import React, { Component } from 'react'
import wechatImg from '../asstes/images/yzwechat.jpg'
export default class SendResult extends Component {
    render() {
        return (
            <div className='sendResultContainer'>
                
                    <h1>发送成功！</h1>
                    <div className='wechatInfo'>
                        <h3>员工查收工资条步骤：</h3>
                        <p>1、用手机微信扫描右边二维码或搜索关注“财华出众”公众订阅号；</p>
                        <p>2、点击菜单栏“i工资条”，输入手机号码及密码即可查询</p>
                    </div>
                    <div className='wechatImg'>
                        <img src={wechatImg} style={{ width: '50%' }}></img>
                    </div>
               
                
            </div>
        )

    }
}