/**
 * combine reducer
 */

import loginReducer from './loginReducer'
import applicationReducer from './applicationReducer'



export default {
    loginReducer, applicationReducer
}