import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Button, Modal, Card, DatePicker, InputNumber, Space, message,Form,Radio,Input, } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment'
import { getUserList, updateUser } from '../redux/action/applicationAction'


const { TextArea } = Input;
const { Search } = Input;
const userType = {
    0: '按年付费',
    1: '永久免费',
    2: '一次性付费',
    3:'试用'
}

@connect(state => state)
class userList extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            pageSize: 10,
            total: 0,
            visible: false,
            currentUserId: '',
            record: null,
            phone: '',
            loading:false,
        }
    }
    componentDidMount() {
        this.fetchUserList()
    }
    fetchUserList() {
        const { dispatch } = this.props
        const { page, pageSize,phone } = this.state
        const params = {
            pageNum: page,
            pageSize: pageSize,
            phone:phone
        }
        this.setState({
            loading: true
        })
        dispatch(getUserList(params))
            .then(json => {
                if (json.success) {
                    this.setState({
                        data: json.data.rows,
                        total: json.data.total,
                        loading: false,
                    })
                }
                
            })
    }
    onChangePage = (page) => {
        this.setState({
            page:page
        }, () => {
            this.fetchUserList()
        })
    }
    editUser = (record) => {
        console.log(this.formRef)
        this.setState({
            visible: true,
            currentUserId: record.id,
            record:record
        }, () => {
            // this.formRef.current.setFieldsValue({
            //     type: record.type,
            //     salePrice: record.salePrice,
            //     validTime: record.validTime,
            //     comment: record.comment,
            // });
        })
    }
    closeModal = () => {
        this.setState({
            visible:false
        })
    }
    handleSubmit = values => {

        this.formRef.current.validateFields().then((values) => {
            const { dispatch } = this.props
            console.log(values)
            values.id = this.state.currentUserId
            values.uid = this.props.loginReducer.userId
            values.validTime = moment(values.validTime).format('YYYY-MM-DD')
            dispatch(updateUser(values))
            .then(json => {
                if (json.success) {
                    message.success('修改成功')
                    this.setState({
                        visible:false
                    })
                    this.fetchUserList()
                }  
            })
        })

    }
    onSearch = value => {
        console.log(value);
        this.setState({
            phone:value
        }, () => {
            this.fetchUserList()
        })
    }

    render() {
        const { data, page, pageSize, total,visible,record,loading } = this.state
        const paginationProps = {
            current: page,
            pageSize: pageSize,
            total: total,
            showTotal: (total, range) => `共 ${total} 条`,
            onChange: this.onChangePage 
        }
        
        const columns = [
            {
                title: '用户ID',
                dataIndex:'id',
            },
            {
                title: '手机号',
                dataIndex: 'userName',
            },
            {
                title: '用户类型',
                dataIndex: 'type',
                render: (text, record) => {
                    
                    return <span>{userType[text]}</span>
                }
            },
            {
                title: '使用到期日',
                dataIndex: 'validTime',
                render: (text, record) => {
                    return <span>{moment(text).format('YYYY-MM-DD')}</span>
                }
                
            },
            {
                title: '价格',
                dataIndex:'salePrice',
            },
            {
                title: '备注',
                dataIndex:'comment',
            },
            {
                title: '操作',
                dataIndex: 'aciton',
                render: (text, record) => {
                    return <Space>
                        <Button type="link" onClick={this.editUser.bind(this,record)}>编辑</Button>
                        {/* <Button type="link" danger>注销</Button> */}
                        
                    </Space>
                }
            }

        ]
        return (

            <div>
                <Card title='用户列表' extra={<Search placeholder="请输入手机号查询" onSearch={this.onSearch} enterButton />}>
                    <Table
                        dataSource={data}
                        columns={columns}
                        pagination={paginationProps}
                        loading={loading}
                    />
                </Card>
                <Modal
                    title={record ? `编辑用户：${record.userName}` :''}
                    visible={visible}
                    destroyOnClose={true}
                    onCancel={this.closeModal}
                    onOk={this.onOk}
                    footer={null}
                >   
                    <Form
                        layout="vertical"
                        ref={this.formRef}
                        onFinish={this.handleSubmit}
                        initialValues={ record ?
                            {
                                type: record.type,
                                salePrice: record.salePrice,
                                validTime: moment(record.validTime),
                                comment: record.comment
                            } : null
                        }
                    >
                        <Form.Item
                            name='type'
                            label='用户类型'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择用户类型',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={0}>按年付费</Radio>
                                <Radio value={1}>永久免费</Radio>
                                <Radio value={2}>一次性付费</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='salePrice'
                            label='用户价格'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户价格',
                                },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name='validTime'
                            label='到期时间'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择到期时间',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            name='comment'
                            label='备注'
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }} >
                            <Button  htmlType="submit" type='primary' size='large'>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default userList