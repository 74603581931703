import React, { Component } from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Route, NavLink } from 'react-router-dom'
import { Layout, Select, Col, Menu, Button, Alert,Modal  } from 'antd'
import { loginRoutes, routes, selectedKeysMap } from '../router/index'
import { loginOut, changeCompany } from '../redux/action/loginAction'
import { getCompanys, getUserInfo  } from '../redux/action/applicationAction'
import { LogoutOutlined, BankOutlined, UserOutlined, FileExcelOutlined, AccountBookOutlined,SettingOutlined} from '@ant-design/icons'
import UpdatePasswordModal from '../components/updatePasswordModal'
import moment from 'moment'

import '../asstes/less/spaLayout.less'
import Cookies from 'js-cookie';

const { Header, Content, Footer, Sider } = Layout
const SubMenu = Menu.SubMenu
const { Option } = Select;

@connect(state => state)
class SpaLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            action: '',
            selectedKeys: [],
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        const { companyName, isLogined, userId} = this.props.loginReducer
        if (!companyName && isLogined) {
            dispatch(getCompanys())
            const params = {
                uid: userId
            }
            dispatch(getUserInfo(params))
        }
        const pathName = this.props.history.location.pathname
        const selectedKey = selectedKeysMap[pathName]
        if (selectedKey) {
            this.setState({
                selectedKeys: [`${selectedKey}`]
            })
        }
        
    }
    componentWillReceiveProps(nextProps) {
        const pathName = nextProps.history.location.pathname
        const selectedKey = selectedKeysMap[pathName]
        if (selectedKey) {
            this.setState({
                selectedKeys: [`${selectedKey}`]
            })
        }

    }

    componentWillMount() {
        const { isLogined } = this.props.loginReducer
        if (!isLogined) {
            this.props.history.push('/login')
        }

    }
    componentDidUpdate() {
        const { pathname } = this.props.location
        const { isLogined, userType } = this.props.loginReducer
        // console.log('didupdate')
        if (pathname.slice(1) === 'login' && isLogined) {
            this.props.history.push('/')
        }
    }
    resetPassword = (ev) => {
        this.setState({
            action: 'viewResetPasswordModal'
        })
    }

    closeAllModal() {
        this.setState({
            action: '',
        })
    }
    addCompany = () => {
        const { dispatch } = this.props
        dispatch(push('/manage'))
    }
    logOut = () => {
        const { dispatch } = this.props
        dispatch(loginOut())
    }
    changeCompany = (value) => {
        const { dispatch } = this.props
        const { companys } = this.props.loginReducer
        const currentCompany = companys.find(item => item.id == value)
        dispatch(changeCompany(currentCompany))
    }
    gotoPay = () => {

        const { dispatch } = this.props
        dispatch(push('/pay'))
    }

    render() {
        const { isLogined, validTime, companys, adminTag, } = this.props.loginReducer
        // console.log(isLogined)
        if (!isLogined) {
            return (
                <div>
                    {loginRoutes.map((item, index) => (
                        <Route
                            key={item.path}
                            path={item.path}
                            component={item.component}
                        />
                    ))}
                </div>
            )
        }

        return (
            <Layout>
                
                <Header className='headerContainer' style={{ width: '100%' }}>
                    <div>
                        <div className="logo">
                        </div>
                    </div>
                    <div className="userInfo">
                        <span>使用到期日：{validTime}</span>
                        <Button type='primary' style={{ margin: '0 24px 0 12px' }} onClick={this.gotoPay}>续费</Button>
                        {companys.length > 0 && 
                        <Select defaultValue={companys[0].id} onChange={this.changeCompany} >
                            {companys.map(item => <Option key={item.id} value={item.id}>{item.companyName}</Option>)}
                        </Select>
                        }
                        <Button onClick={this.logOut} type='link' icon={<LogoutOutlined />}>退出登录</Button>
                        {/* <span className='logoutIcon'><LogoutOutlined   /></span> */}
                    </div>
                    
                </Header>
                <Layout >
                    <Sider width={200} theme='light'>
                        <Menu
                            theme='light'
                            selectedKeys={this.state.selectedKeys}
                            mode="inline"
                            onClick={this.handleMenuClick}
                            defaultOpenKeys={['sub1', 'sub2','sub3']}
                        >
                            <SubMenu icon={<FileExcelOutlined />} key="sub1" title={<span>工资条管理</span>}>
                                <Menu.Item key="new"><NavLink to='/new'>上传工资条</NavLink></Menu.Item>
                                <Menu.Item key="history"><NavLink to='/history'>发送记录</NavLink></Menu.Item>
                                <Menu.Item key="help"><NavLink to='/help'>常见问题</NavLink></Menu.Item>
                            </SubMenu>
                            <Menu.Item icon={<BankOutlined />} key="manage"><NavLink to='/manage'>企业管理</NavLink></Menu.Item>
                            <Menu.Item icon={<UserOutlined />} key="userInfo"><NavLink to='/userInfo'>个人中心</NavLink></Menu.Item>
                            <SubMenu icon={<AccountBookOutlined />} key="sub2" title={<span>账户订单</span>}>
                                <Menu.Item key="pay"><NavLink to='/pay'>续费</NavLink></Menu.Item>
                                <Menu.Item key="payHistory"><NavLink to='/payHistory'>历史订单</NavLink></Menu.Item>
                            </SubMenu>
                            {adminTag > 0 && 
                            <SubMenu icon={<SettingOutlined />} key="sub3" title={<span>后台管理</span>}>
                                <Menu.Item key="userList"><NavLink to='/userList'>用户管理</NavLink></Menu.Item>
                                    <Menu.Item key="orderList"><NavLink to='/orderList'>订单管理</NavLink></Menu.Item>
                            </SubMenu>
                            }
                        </Menu>
                    </Sider>
                    <Content id="wy-scroll-layout">
                        { moment(validTime).diff(moment(),'days')< 30 && 
                        <Alert
                            message={`您的账户过期时间为${validTime}，请及时续费。`}
                            type="warning"
                            showIcon
                            action={
                                <Button size="small" type="link" onClick={this.gotoPay}>
                                    前往续费
                                </Button>
                            }
                        />
                        }
                        <Col span={24} style={{ padding: 24 }}>
                            
                                <div style={{ minHeight: '100vh' }}>
                                    {routes.map((route, index) => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}
                                </div>
                            </Col>
                            <UpdatePasswordModal action={this.state.action} closeAllModal={this.closeAllModal.bind(this)} />
                    </Content>
                    <Modal
                        title='续费提示'
                        visible={this.props.history.location.pathname !== '/pay' && moment(validTime).isBefore(moment())}
                        style={{ textAlign: 'center' }}
                        footer={null}
                        closable={false}
                    >
                        <h3>您的账户已到期，目前已无法使用，请及时续费。</h3>
                        <Button type='primary' onClick={this.gotoPay} style={{marginTop:24}} >续费</Button>
                    </Modal>
                </Layout>
                {/* <Footer style={{ textAlign: 'center' }}>
                版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer> */}
            </Layout>
            
        )
        
    }
}

export default SpaLayout
