import React, { Component } from 'react'
import { Layout, Form,  Input, Button, message, Menu } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { addCompany, getCompanys } from '../redux/action/applicationAction'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

const { Header, Content, Footer } = Layout
const FormItem = Form.Item
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};
let id = 1;
@connect(state => state.loginReducer)
class AddCompany extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            arrList: [{ code: '' }],//用来控制 需要增删列表元素的个数，值无意义
        }


    }
    onLinkWay(index, type) {
        let arr = this.state.arrList
        const obj = { code: '', }
        if (type == 'add') {
            arr.push(obj)
        } else {
            arr.splice(index, 1)
        }
        this.setState({
            arrList: arr,
        })
    }
    fetchGetCompanys() {
        const { dispatch } = this.props
        dispatch(getCompanys())
    }
    handleSubmit = (values) => {

        const { dispatch, userName, companyName } = this.props

        const fecthParam = {
            userName: userName,
            companys: values.companys,
        }
        dispatch(addCompany(fecthParam))
            .then(json => {
                if (json.success) {
                    this.fetchGetCompanys()
                    message.success('添加成功！')
                    dispatch(push('/new'))
                }
            })
        
    }

    render() {

        return (
            <Form
                {...formItemLayoutWithOutLabel}
                onFinish={this.handleSubmit}
                className="addContainer"
                ref={this.formRef}
                initialValues={{ companys: [''] }}
            >
                <Form.List
                    name="companys"
                    
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                    label={index === 0 ? '企业名称' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "请输入企业名称",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="企业名称" style={{ width: '60%' }} />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            style={{marginLeft:8,fontSize:16}}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    添加企业
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit">提交</Button>
                </Form.Item> 
            </Form>
        )
    }
}
export default AddCompany