import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Radio, DatePicker, Modal, Upload, message, Button,  } from 'antd'
import { UploadOutlined,} from '@ant-design/icons';
import moment from 'moment';
import { baseUrl } from '../util/config'
import { checkWage, resolveExcel, } from '../redux/action/applicationAction'
// import 'moment/locale/zh-cn';
// moment.locale('zh-cn');
import locale from 'antd/lib/date-picker/locale/zh_CN';

const RadioGroup = Radio.Group;
const { MonthPicker } = DatePicker;
const FormItem = Form.Item

@connect(state => state)
class UploadExcel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            month: moment().subtract(1, 'months').format("YYYY-MM"),
            visible: false,
            isChecked: false,
            checkedSheetName: '',
            sheetNames: [],
            fileId: '',
            confirmLoading: false,
        }
    }
    componentDidMount() {
        const { companyName } = this.props.loginReducer
        if (companyName.length > 0) {
            this.fetchCheckWage(companyName)
        }
        
    }
    componentWillReceiveProps(nextProps) {
        const companyName1 = this.props.loginReducer.companyName
        const companyName2 = nextProps.loginReducer.companyName
        // console.log(companyName1, companyName2)
        if (companyName1 !== companyName2) {
            console.log('切换了企业')
            this.fetchCheckWage(companyName2)
        }
    }
    TimeChange = (date, dateString) => {
        const { companyName } = this.props.loginReducer
        this.setState({
            month: dateString
        }, () => {
            this.fetchCheckWage(companyName)
        })


    }
    fetchCheckWage(companyName) {
        const { month } = this.state
        const { dispatch } = this.props
        const params = {
            companyName: companyName,
            month: month
        }
        dispatch(checkWage(params))
            .then(json => {
                if (json.success) {
                    if (json.data) {
                        this.setState({
                            isChecked: true
                        })
                    } else {
                        this.setState({
                            isChecked: false
                        })
                    }
                }
            })
    }
    onRadioChange = (e) => {

        this.setState({
            checkedSheetName: e.target.value,
        });
    }
    handleOk = (e) => {

        const { month, checkedSheetName, fileId } = this.state
        if (!checkedSheetName) {
            message.error('请选择工资表名！')

            return false
        }
        this.setState({
            confirmLoading: true,
        });
        const { dispatch } = this.props
        const { companyName, companyId } = this.props.loginReducer
        const { changeCurrent, setData, setMonth, } = this.props
        const params = {
            month: month,
            companyId: companyId,
            sheetName: checkedSheetName,
            fileId: fileId,
        }
        dispatch(resolveExcel(params))
        .then(json => {
            if (json.success) {
                changeCurrent(1)
                setMonth(month)
                setData(json.data)
            }
            this.setState({
                confirmLoading: false,
                visible: false,
            })
        })
    }

    handleCancel = (e) => {

        this.setState({
            visible: false,
            confirmLoading: false,
        });
    }
    // beforeUpload =(file) =>{
    // 	const { companyName,} = this.props.loginReducer

    // }
    render() {

        const { month, visible, sheetNames, confirmLoading } = this.state
        const { companyName, token } = this.props.loginReducer
        let _this = this
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const props = {
            name: 'file',
            action: `${baseUrl}/api/salary/upload`,
            accept: '.xlsx,.xls',
            data: {
                month: this.state.month,
                companyName: companyName,
            },
            showUploadList: false,
            headers: {
                token: token,
            },
            // beforeUpload:this.beforeUpload,
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response.success) {
                        // message.success('上传成功');
                        _this.setState({
                            visible: true,
                            sheetNames: info.file.response.data.sheetNames,
                            fileId: info.file.response.data.fileId,

                        })

                    } else {

                        message.error(info.file.response.message)
                    }

                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败`);
                }
            },
        }
        return (
            <div className='uploadContainer'>
                <div>
                    <h2>上传Excel工资表</h2>
                    <h3>需包含手机号码</h3>
                </div>
                <div>
                    <span className='ant-form-item-required'>工资所属月份：</span>
                    <MonthPicker
                        allowClear={false}
                        locale={locale}
                        defaultValue={moment().subtract(1, 'months')}
                        onChange={this.TimeChange} placeholder="请选择年月"
                        disabledDate={(current) => current > moment().endOf('day')}
                    />
                    {this.state.isChecked ? <p className='info'>该月份数据已存在，继续上传将会替换为最新数据！</p> : null}
                </div>
                <div style={{ margin: '38px 0' }}>
                    <Upload {...props} >
                        <Button type="primary">
                            <UploadOutlined /> 上传文件
						</Button>
                    </Upload>
                </div>
                <div style={{ fontSize: '12px' }}>
                    
                    <p>1、需有一列包含员工手机号码，用于接收初始密码和验证码</p>
                    <p>2、某行工资条对应的手机号码为空值时，该行工资条将不上传</p>
                    <p>3、请避免使用微信浏览器和IE浏览器，建议使用Chrome浏览器</p>
                    <p>4、如遇到系统报错请尝试点击右上角“退出登录”然后重新登录</p>
                    <p>5、使用过程中如有疑问，请加客服QQ1987960997咨询</p>
                </div>
                <Modal
                    title='选择工资表'
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                    okText='确定'
                    cancelText='取消'
                    maskClosable={false}
                >

                    <RadioGroup onChange={this.onRadioChange} value={this.state.checkedSheetName}>
                        {sheetNames.map(item => <Radio value={item} style={radioStyle}>{item}</Radio>)}
                    </RadioGroup>

                </Modal>
            </div>
        )


    }
}
export default UploadExcel