import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { Card, Descriptions, Button,Modal,Form,Input,Row,Col,message } from 'antd'
import UpdatePasswordModal from "../components/updatePasswordModal";
import { getCode, updatePhone, getUserInfo, checkPhoneCode } from '../redux/action/applicationAction'
import { loginOut } from '../redux/action/loginAction'
import moment from 'moment'

const FormItem = Form.Item
const userType = {
    0: '按年付费',
    1: '永久免费',
    2: '一次性付费',
    3:'试用'
}
let countDownNum = 60
let countDownStr = ''
let timer
@connect(state => state)
class userInfo extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            
            updatePasswordModal_visible: false,
            countDown: false,
            countDownStr: '',
            BizId: '',
            updatePhoneModal_visible: false,
            type: '',
            validTime: '',
            salePrice:""
        }
    }
    componentDidMount() {
        const { dispatch } = this.props
        // console.log(this.props.loginReducer.userId)
        const params = {
            uid:this.props.loginReducer.userId
        }
        dispatch(getUserInfo(params))
            .then(json => {
                if (json.success) {
                    this.setState({
                        type: json.data.type,
                        validTime: json.data.validTime,
                        salePrice: json.data.salePrice
                    })
                }
                
            })

    }
    componentWillUnmount() {
        //清除事件
        clearTimeout(timer)
    }
    showUpdatePasswordModal = () => {
        this.setState({
            updatePasswordModal_visible: true
        })
    }

    closeModal = () => {
        this.setState({
            updatePasswordModal_visible: false,
            updatePhoneModal_visible:false,
        })
    }
    getPhoneCode = (props) => {

        const { dispatch } = this.props
        const phoneNum = this.formRef.current.getFieldValue('userName')
        const TemplateCode = 'SMS_160230403'
        // console.log(phoneNum)
        if (phoneNum) {
            if (phoneNum === this.props.loginReducer.userName) {
                message.error('新号码不能与原号码相同')
                return
            }
            this.setState({
                countDown: true
            })
            const params = {
                phone: phoneNum,
                templateCode: TemplateCode
            }
            dispatch(getCode(params))
            .then(json => {
                if (json.success) {
                    this.setState({
                        BizId: json.data
                    })
                }
            })
            this.setTime(countDownNum)
        } else {
            message.error('请输入手机号码！')
        }
    }
    onOk = () => {
        const { dispatch } = this.props
        
        this.formRef.current.validateFields().then((values) => {
            console.log(values)
            values.bizId = this.state.BizId
            dispatch(checkPhoneCode(values))
            .then(json => {
                if (json.success) {
                    const {  userId,userName, } = this.props.loginReducer
                    const params = {
                        uid: userId,
                        oldPhone: userName,
                        newPhone:values.userName
                    }
                    dispatch(updatePhone(params))
                        .then(json => {
                            if (json.success) {
                                message.success('修改成功，请重新登录')
                                dispatch(loginOut())
                                // this.closeModal()
                            }
                        })
                }
            })
            

        })
    }

    setTime = (countDownNum) => {
        countDownNum--
        countDownStr = countDownNum + 'S后重新获取'
        this.setState({
            countDownStr: countDownStr
        })
        timer = setTimeout(() => {
            this.setTime(countDownNum)
        }, 1000)
        if (countDownNum == 0) {
            this.setState({
                countDown: false
            })
            clearTimeout(timer)
        }

    }
    showUpdatePhoneModal = () => {
        this.setState({
            updatePhoneModal_visible:true
        })
    }

    render() {
        const { updatePasswordModal_visible, countDown, countDownStr, updatePhoneModal_visible, type,
            validTime, salePrice
        } = this.state
        const { userName } = this.props.loginReducer
        const phoneRule = "^[1][3,4,5,6,7,8,9][0-9]{9}$"
        const GetCodeButton = countDown ? (
            <Button type="primary" style={{ marginLeft: '10px', width: '120px' }} disabled >
                {countDownStr}
            </Button>
        ) : (
            <Button type="primary" style={{ marginLeft: '10px', width: '120px' }} onClick={this.getPhoneCode} >
                获取验证码
            </Button>
        )
        return (
            <div>
                <Card title='个人信息'>
                    <Descriptions column={1}>
                        <Descriptions.Item label="账户">
                            <div>
                                <span>{userName}</span>
                                <Button type='link' size='small' style={{ marginLeft: 24 }} onClick={this.showUpdatePhoneModal} >修改登录手机</Button>
                            </div>
                            
                        </Descriptions.Item>
                        <Descriptions.Item label="密码">
                            <div>
                                <span>********</span>
                                <Button type='link'  size='small' style={{ marginLeft: 24 }} onClick={this.showUpdatePasswordModal}>修改密码</Button>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label='用户类型'>
                            <div>{userType[type]}</div>
                        </Descriptions.Item>
                        <Descriptions.Item label='过期时间'>
                            <div>{moment(validTime).format('YYYY-MM-DD')}</div>
                        </Descriptions.Item>
                        {type !== 3  && type !== 1 &&
                        <Descriptions.Item label='销售价格'>
                            <div>{salePrice}</div>
                        </Descriptions.Item>
                        }
                    </Descriptions>
                </Card>
                <UpdatePasswordModal visible={updatePasswordModal_visible} closeModal={this.closeModal} />
                <Modal
                    title='修改登录手机'
                    visible={updatePhoneModal_visible}
                    onCancel={this.closeModal}
                    onOk={this.onOk}
                    destroyOnClose={true}
                >
                    <Form className="wy-modal-form" ref={this.formRef} >
                        <FormItem
                            label="新手机"
                            name="userName"
                            rules={[{ required: true, message: '请输入有效的手机号', pattern: new RegExp(phoneRule) }]}
                        >
                            <Input placeholder="请输入新手机号" style={{ width: '280px' }} />
                        </FormItem>
                        <Form.Item label="验证码" className='addRequiredIcon'>
                            <Row>
                                <Col >
                                    <Form.Item
                                        name="code"
                                        noStyle
                                        rules={[{ required: true, message: '请输入验证码', },]}
                                    >
                                        <Input placeholder="请输入验证码" style={{ width: '150px' }} />
                                    </Form.Item>
                                </Col>
                                <Col >
                                    {GetCodeButton}
                                </Col>
                            </Row>
                        </Form.Item>
                        
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default userInfo
