import React from 'react';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'react-router-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import { Switch, withRouter, BrowserRouter } from 'react-router-dom'
import { store, history } from './redux/store'

import SpaLayout from './view/spaLayout'
import './App.less';

const WithRouterSPALayout = withRouter(SpaLayout)

function App() {
	return (
		<Provider store={store}>
            <ConnectedRouter history={history}>
				<ConfigProvider locale={zhCN}>
					<WithRouterSPALayout />
                </ConfigProvider>
			</ConnectedRouter>
				
		</Provider>
	);
}

export default App;
