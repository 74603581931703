import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, Form, Modal, Button, InputNumber, Input, Radio, message,  } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { creatOrder } from '../redux/action/applicationAction'

const FormItem = Form.Item
const { TextArea } = Input;
const { confirm } = Modal;

@connect(state => state)
class pay extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            payMode: '0',
            method: 1,
            visible: false,
            payPrice: 0,
            num:1
        }
    }
    onChangePayType = (e) => {
        this.setState({
            payMode:e.target.value
        })
    }
    onChangeMethod = (method) => {
        this.setState({
            method
        })
    }
    onChangeNum = (value) => {
        this.setState({
            num:value
        })
    }
    handleCancel = () => {
        this.setState({
            visible:false
        })
    }

    handleOk = () => {

        const { dispatch } = this.props
        const { type, salePrice, userId } = this.props.loginReducer
        const { num, payMode, comment } = this.state
        const params = {
            num: num,
            payMode: payMode,
            uid: userId,
            comment: comment
        }
        dispatch(creatOrder(params))
            .then(json => {
                if (json.success) {
                    message.success('订单创建成功')
                    this.setState({
                        visible: false
                    })
            }
        })
    }
    onFinish = (values) => {
        console.log(values)
        const { type, salePrice,userId } = this.props.loginReducer
        const { num, payMode,} = this.state
        const { dispatch } = this.props
        const price = salePrice * num
        this.setState({
            comment: values.comment,
        }, () => {
            if (values.payMode == 1) { //对公打款
                confirm({
                    title: '确认已完成转账？',
                    icon: <ExclamationCircleOutlined />,
                    content: `您的订单金额为${price}元`,
                    okText: '确定',
                    onOk() {
                        console.log('OK');
                        const params = {
                            num: num,
                            payMode: payMode,
                            uid: userId,
                            // comment: comment
                        }

                        dispatch(creatOrder(params))
                            .then(json => {
                                if (json.success) {
                                    message.success('订单创建成功')
                                }
                            })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                confirm({
                    title: '即将跳转到支付宝支付页面',
                    icon: <ExclamationCircleOutlined />,
                    content: `您的订单金额为${price}元`,
                    okText: '前往支付',
                    onOk() {
                        console.log('OK');
                        const params = {
                            num: num,
                            payMode: payMode,
                            uid: userId,
                            // comment: comment
                        }

                        const newTab = window.open();
                        dispatch(creatOrder(params))
                            .then(json => {
                                if (json.success) {
                                    // message.success('订单创建成功')
                                    // const newWindow = window.open("",)
                                    // newWindow.document.write(json.data);
                                    // newWindow.focus();

                                    const div = document.createElement('div');
                                    div.innerHTML = json.data; // html code
                                    newTab.document.body.appendChild(div);
                                    newTab.document.forms.punchout_form.acceptCharset = 'UTF-8'
                                    newTab.document.forms.punchout_form.submit();
                                }
                            })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
        })
        
       
    }

    render() {
        const { num, visible,payMode } = this.state;
        const { type, salePrice } = this.props.loginReducer
        if (type == 3) { //试用客户
            return <h3 style={{textAlign:'center',marginTop:100}}>您当前为试用客户，若要续费请咨询客服QQ1987960997</h3>
        }
        return (
            <div className='payPage'>
                <Card>
                    {type == 0 && <div>您的账户当前费用为：{salePrice}元/年</div>}
                    {type == 1 && <div>您的账户当前费用为：永久免费</div>}
                    {type == 2 && <div>您的账户当前费用为：{salePrice}</div>}
                </Card>
                { type !== 1 &&
                    <Card style={{ marginTop: 12,}}>
                        <Form
                            ref={this.formRef}
                            layout='vertical'
                            onFinish={this.onFinish}
                            initialValues={{
                                payMode: '0',
                                num:1,
                            }}
                        >
                            <FormItem
                                name='payMode'
                                label='请选择支付方式：'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择支付方式',
                                    },
                                ]}
                            >
                                <Radio.Group defaultValue='0' buttonStyle="solid" onChange={this.onChangePayType}>
                                    <Radio.Button value="0">支付宝</Radio.Button>
                                    <Radio.Button value="1">对公打款</Radio.Button>
                                </Radio.Group>
                            </FormItem>
                            {type == 0 && 
                                <FormItem
                                    name='num'
                                    label='请输入续费年限：'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入续费年限',
                                        },
                                    ]}
                                >
                                    <InputNumber defaultValue={1} min={1} max={99} precision={0} onChange={this.onChangeNum} />
                                </FormItem>
                            }
                            {/* <FormItem
                                name='comment'
                                label='备注信息：'
                            >
                                <TextArea rows={2} style={{width:300}} />
                            </FormItem> */}
                            <Form.Item>
                                <h3>订单总金额：{ salePrice * num }</h3>
                            </Form.Item>
                            <Form.Item
                            >
                                <Button type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                        { payMode === '1' && 
                        <div>
                            <p>名称：杭州仰正网络科技有限公司</p>
                            <p>银行：浙江民泰商业银行股份有限公司杭州分行</p>
                            <p>银行账号：583851312700015</p>
                            <p>联行号：313331005018</p>
                            <p>温馨提示：请向以上账户转账¥{salePrice * num}，转账时请备注手机号码。转账完成后，请点击“确定”按钮，平台客服会根据到账情况在2小时内完成审核，审核完成后使用期限自动延续。</p>
                            {/* <h3>请向以上账户转账¥{salePrice * num}，付款成功后点击“已付款”按钮，平台客服会根据到账情况在2小时内完成审核，审核完成后使用期限自动延续。</h3> */}
                        </div>
                        }

                        
                    
                    
                    <Modal
                        title='对公打款信息'
                        visible={visible}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                            取消
                            </Button>,
                            <Button key="submit" type="primary"  onClick={this.handleOk}>
                                已付款
                            </Button>,
                        ]}
                    >
                        <div>
                            <p>名称：杭州仰正网络科技有限公司</p>
                            <p>银行：浙江民泰商业银行股份有限公司杭州分行</p>
                            <p>银行账号：583851312700015</p>
                            <p>联行号：313331005018</p>
                                <h3>请向以上账户转账¥{ salePrice * num }，付款成功后点击“确定”按钮，平台客服会根据到账情况在2小时内完成审核，审核完成后使用期限自动延续。</h3>
                        </div>
                    </Modal>
                    </Card>
                }
            </div>
        )
    }
}

export default pay;
