import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Tabs, Layout, Steps,  Button,  Card, } from 'antd'
import { updateWageVisible } from '../redux/action/applicationAction'
import UploadExcel from '../components/uploadExcel'
import ConfirmData from '../components/confirmData'
import SendResult from '../components/sendResult'
const TabPane = Tabs.TabPane
const Step = Steps.Step;
const { Header, Content, Footer, Sider } = Layout

const steps = [{
    title: '上传工资条',
}, {
    title: '核对数据',
}, {
    title: '发送',
}]
@connect(state => state)
class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            data: null,
            month: '',
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        const companyName1 = this.props.loginReducer.companyName
        const companyName2 = nextProps.loginReducer.companyName
        if (companyName1 !== companyName2) {
            this.setState({
                current: 0,
            })
        }
    }
    changeCurrent(current) {
        this.setState({
            current: current,
        })
    }
    setData(data) {
        this.setState({
            data: data
        })
    }
    setMonth(month) {
        this.setState({
            month: month
        })
    }
    prev() {
        const current = this.state.current - 1
        this.setState({ current })

    }
    next() {
        const current = this.state.current + 1
        this.setState({ current })
        const { dispatch } = this.props
        const { companyName } = this.props.loginReducer
        const { month } = this.state
        const obj = {
            companyName: companyName,
            month: month,
        }
        dispatch(updateWageVisible(obj))
    }

    render() {
        const { current } = this.state
        return (
            <div>
                <Card title='上传工资条'>
                    <div className='stepsHeader'>
                        <Steps current={current}>
                            {steps.map(item => <Step key={item.title} title={item.title} />)}
                        </Steps>
                    </div>
                    <div className="steps-content" >
                        {
                            this.state.current === 0
                            &&
                            <UploadExcel  changeCurrent={this.changeCurrent.bind(this)} setData={this.setData.bind(this)} setMonth={this.setMonth.bind(this)} />
                        }
                        {
                            this.state.current === 1
                            &&
                            <ConfirmData  data={this.state.data} />
                        }
                        {
                            this.state.current === 2
                            &&
                            <SendResult  />
                        }
                    </div>
                    {
                        this.state.current === 1
                        &&
                        <div className="steps-action">
                            <p>请核对数据是否正确，如无误，请点击立即发送；如有误，请点击上一步，重新导入正确的数据</p>
                            <Button type="primary" size="large" style={{ marginRight: '25px' }} onClick={() => this.prev()}>上一步</Button>
                            <Button type="primary" size="large" style={{ marginLeft: '25px' }} onClick={() => this.next()}>立即发送</Button>

                        </div>
                    }
                </Card>
                
            </div>
        )
    }
}

export default New