import React, { Component } from 'react'
import { Layout, Form, Checkbox, Input, Button, message, Menu, Modal,Row,Col } from 'antd'
import 'whatwg-fetch'
import { accountRegister } from '../redux/action/loginAction'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { BrowserRouter as Router, Route, Link, Redirect, NavLink } from 'react-router-dom'
import { getCode } from '../redux/action/applicationAction'



const { Header, Content, Footer } = Layout
const FormItem = Form.Item
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const agreementLayout = {
    wrapperCol: {
        xs: {
            offset: 0,
        },
        sm: {
            offset: 6,
        },
    },
};
let countDownNum = 60
let countDownStr = ''
let timer

@connect(state => state.loginReducer)
class Register extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false,
            countDown: false,
            countDownStr: '',
            BizId: '',
            visible: false,
        }

    }
    setTime = (countDownNum) => {
        countDownNum--
        countDownStr = countDownNum + 'S后重新获取'
        this.setState({
            countDownStr: countDownStr
        })
        timer = setTimeout(() => {
            this.setTime(countDownNum)
        }, 1000)
        if (countDownNum == 0) {
            this.setState({
                countDown: false
            })
            clearTimeout(timer)
        }

    }
    getReisterCode = () => {

        const { dispatch } = this.props
        const phoneNum = this.formRef.current.getFieldValue('userName')
        const TemplateCode = 'SMS_160230404'
        // console.log(phoneNum)
        if (phoneNum) {
            this.setState({
                countDown: true
            })
            const params = {
                phone: phoneNum,
                templateCode: TemplateCode
            }
            dispatch(getCode(params))
                .then(json => {
                    if (json.success) {
                        this.setState({
                            BizId: json.data
                        })
                    }
                })
            this.setTime(countDownNum)
        } else {
            message.error('请输入手机号码！')
        }
    }
    componentWillUnmount() {
        //清除事件
        clearTimeout(timer)
    }
    handleSubmit = values => {

        const { dispatch } = this.props
        values.bizId = this.state.BizId
        dispatch(accountRegister(values))
        
    }
    gotoLogin() {
        const { dispatch } = this.props
        dispatch(push('/login'))
    }
    
    showAgreement() {
        this.setState({
            visible: true
        })
    }
    handleCancel = (e) => {

        this.setState({
            visible: false,
        });
    }
    render() {
        const pwdRule = "^[A-Za-z0-9]{6,16}$"
        const GetCodeButton = this.state.countDown ? (
            <Button type="primary" style={{ marginLeft: '10px', width: '120px' }} disabled onClick={this.getReisterCode.bind(this, this.props)}>
                {this.state.countDownStr}
            </Button>
        ) : (
                <Button type="primary" style={{ marginLeft: '10px', width: '120px' }} onClick={this.getReisterCode.bind(this, this.props)}>
                获取验证码
            </Button>
        )
        return (
            <Layout className='registerContainer'>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo">
                    </div>

                    <Menu
                        // theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['login']}
                        style={{ lineHeight: '64px' }}
                    >
                        <Menu.Item key="login"><NavLink to='/login'>首页</NavLink></Menu.Item>
                        <Menu.Item key="introduce"><NavLink to='/introduce'>服务介绍</NavLink></Menu.Item>
                        <Menu.Item key="about"><NavLink to='/about'>关于我们</NavLink></Menu.Item>
                    </Menu>
                </Header>
                <Content style={{ marginTop: 64 }}>
                    <Form
                        {...formItemLayout}
                        onFinish={this.handleSubmit}
                        className="register-form"
                        ref={this.formRef}
                    >
                        <h1>请注册您的HR账号</h1>
                        <FormItem
                            label="手机号"
                            name="userName"
                            rules={[{ required: true, message: '请输入手机号', }]}
                        >
                            <Input placeholder="手机号" style={{ width: '280px' }} />
                        </FormItem>
                        <Form.Item label="验证码" className='addRequiredIcon'>
                            <Row>
                                <Col >
                                    <Form.Item
                                        name="code"
                                        noStyle
                                        rules={[{ required: true, message: '请输入验证码', },]}
                                    >
                                        <Input placeholder="请输入验证码" style={{ width: '150px' }} />
                                    </Form.Item>
                                </Col>
                                <Col >
                                    {GetCodeButton}
                                </Col>
                            </Row>
                        </Form.Item>
                        <FormItem
                            label='密码'
                            name="userPassword"
                            rules={[{ required: true, message: '请输入6-16位数字或字母', pattern: new RegExp(pwdRule) }]}
                        >
                            <Input.Password placeholder="请输入6-16位数字或字母" style={{ width: '280px' }} />
                        </FormItem>
                        <FormItem
                            label="确认密码"
                            name="confirm"
                            dependencies={['userPassword']}
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入新密码'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('userPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('密码两次输入不一致');
                                    }
                                })
                            ]}
                        >
                            <Input.Password type="password" placeholder="请再次输入新密码" style={{ width: '280px' }} />
                        </FormItem>
                        <FormItem
                            {...tailFormItemLayout}
                            name="agreement"
                            valuePropName="checked"
                            style={{ textAlign: "left" }}
                            rules={[{ required: true, message: '请同意服务协议', }]}
                        >
                            <Checkbox>我已阅读并同意 <Button type='link' style={{padding:0}}  onClick={this.showAgreement.bind(this)}>服务协议</Button></Checkbox>
                        </FormItem>
                        <FormItem {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" style={{ width: '180px' }} className="login-form-button">
                                注册
							</Button>
                        </FormItem>
                        <p>已有账号？<a className="login-form-forgot" onClick={this.gotoLogin.bind(this)}>立即登录</a></p>
                    </Form>

                    <Modal
                        title="服务协议"
                        className='agreeModal'
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        maskClosable={false}
                        footer={null}
                    >
                        <h3>首部及导言</h3>
                        <p>欢迎使用i工资条！</p>
                        <p>“i工资条”是由杭州仰正网络科技有限公司（以下简称“本公司”）开发和运营的应用（以下简称“本应用”）。 为使用本应用，贵司务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。免除或限制责任条款可能以加粗形式提示贵司注意。
                        除非贵司已阅读并接受本协议所有条款，否则贵司无权使用本应用。贵司注册成为本应用用户，即视为贵司已充分阅读、理解并接受本协议的全部内容，同意受本协议的约束。
						</p>
                        <h3>一、 用户注册</h3>
                        <p>1.1用户是指实际使用本应用的企业法人或其他组织（包括股份公司、有限公司、合伙企业、个人独资企业、个体工商户等）。</p>
                        <p>1.2注册本应用、签署本协议等行为都由贵司委托的自然人（实际操作者）完成。</p>
                        <p>1.3贵司必须完全同意并接受本协议，并按照本应用要求完成注册程序之后，贵司即成为注册用户并可使用本应用。</p>
                        <p>1.4贵司系经有权机关批准依法设立的法人或其他组织且有效存续。若贵司不具备前述主体资格，则本公司有权注销贵司的用户资格，
                        并由贵司自行承担因此产生的全部法律责任。
						</p>
                        <p style={{ fontWeight: 'bolder' }}>1.5贵司应保证本应用的实际操作者，已获得贵司的合法、全面、有效授权，该授权允许实际操作者注册、使用本应用，
                        并代表贵司与本公司进行协议的线上签署操作。该操作者注册、使用本应用的行为视为贵司做出，因此产生的所有法律后果全部
                        由贵司承担。
						</p>
                        <p>1.6本公司可随时自行全权决定更改本协议条款。任何修订和新规则一经公布即自动成为本款的一部分。用户登录或继续使用服务
                        的行为将被视为接受上述规则修改并愿意继续履行协议的意思表示。
						</p>
                        <h3>二、 服务约定</h3>
                        <p>2.1用户注册成功后，本应用将给予贵司的每个用户一个/多个账号及相应的密码，贵司应保证账号的使用者为贵司授权的自然人。</p>
                        <p>2.2用户账号和密码由用户负责保管；用户可随时改变自己的密码。用户应当对以其用户账号进行的所有活动和事件承担法律责任，
                        用户若发现任何非法使用本人账号或安全漏洞的情况，立即通告本公司。
						</p>
                        <p>2.3本公司尽合理的努力保证本应用正常运营，除非：
                        （1）进行系统维护升级（为保障服务质量本应用将视情况对系统进行维护升级），
                        或 （2）发生超出本应用控制能力的不可抗力事件，包括天灾、政府行为、洪水、火灾、地震、内乱、恐怖活动、网络供应商失败、
                        延迟或拒绝服务等。
							</p>
                        <p>2.4本公司保证：（1）提供的服务符合法律法规；（2）将以专业的态度为贵司提供服务。 </p>
                        <p>2.5贵司同意并认可，本应用的服务是按照“现有状况且包含所有错误”的形式提供的，
                        本公司仅就2.4款中的事项作出保证，不对除2.4款外的任何事项做出任何明示或默示的保证，
                        包括相关软件、文档、成果或其他材料和/或服务的适销性保证、符合特定目的保证。
							且本公司不保证本应用的各项服务在操作中不会出现错误或中断。 </p>
                        <p>2.6贵司同意并接受，本公司有权随时终止或中断本应用的各项服务或者其部分功能。对于终止或中断免费功能的，
                        本公司不需承担任何法律责任；对于终止或中断付费功能的，本公司最大和唯一的补偿责任是向贵司退还贵司已经支付
							的未履行期间的服务费。 </p>
                        <h3>三、 用户注销</h3>
                        <p>3.1用户同意本协议一经接受将长期有效，除非：（1）本协议下的全部服务到期，或者;
                        （2）贵司或本公司依据本协议约定终止本协议，或者;（3）贵司账号依据本协议约定被注销，或者;
							（4）本应用提供的服务因法律或政府命令等不可抗力而必须终止。</p>
                        <p>3.2休眠账号：若贵司在连续的十二个月内未进行登录操作的，则贵司的账号将被视为休眠状态。
                        休眠状态的账号将被视为不再使用，本应用有权注销贵司的该账号并删除与该账号相关的任何数据。
							若贵司需要重新使用本应用的各项服务，须重新申请用户账号。</p>
                        <h3>四、 用户行为规范</h3>
                        <p>4.1在服务期内，本应用授予贵司出于自身商业使用目的访问和使用依据本应用的要求开通的各项服务。
                        该授权是有限的、不可转让的、不可转授、非排他性的。本协议中的任何条款不得解释成可要求本应用向贵司提供
							或使贵司有权获得任何计算机软件的副本或软件的代码（无论是源代码或目标代码）。</p>
                        <p>4.2贵司同意在符合法律法规的前提下使用本应用的各项服务，不得利用本应用制作、复制、发布、传播如下干扰本公司业务正常运营，
							以及侵犯其他用户或者第三方合法权益的内容，包括但不限于：</p>
                        <p>（1） 发布、传播、储存违反国家法律法规禁止的内容；  </p>
                        <p>（2） 发布、传播、储存侵犯他人名誉权、肖像权、知识产权、商业秘密等合法权益的内容； </p>
                        <p>（3） 涉及他人隐私、个人信息或资料的； </p>
                        <p>（4） 发表、传播骚扰、广告信息及垃圾信息的； </p>
                        <p>（5） 其他违反法律法规、政策及社会公德或干扰本应用正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>

                        <h3>五、 用户数据</h3>
                        <p>5.1贵司为上传的数据的真实性、准确性、完整性负责。因贵司提供错误或虚假信息或未及时更新联系方式导致贵司在使用
                        本应用过程中产生任何法律责任或经济损失，均由贵司自行承担。如果贵司提供的用户数据包含第三人的信息，
							则由贵司应获得第三人的授权许可，如未获得第三人授权而产生的所有法律后果全部由贵司承担。 </p>
                        <p>5.2尊重用户隐私是本应用的基本准则。本应用将通过商业上合理的物理、技术措施，保证贵司的数据的安全性、保密性和完整性。
                        本应用保证不披露贵司的可识别身份数据（指可以识别或确定组织或个人身份的贵司数据，例如具体的电子邮箱地址、电话号码、
							名称、姓名、住址），除非根据法律或政府要求或贵司的明确书面授权。</p>
                        <p>5.3为了提供、维持、改善本应用提供的各项服务，用户同意并授权本应用： （1）有权存储、访问、收集和分析贵司的数据，
                        以及有权委托专业的第三方云存储服务机构存储贵司的数据； （2）在不透露用户可识别身份数据的前提下，本应用有权对整个
                        用户数据库进行分析并对用户数据库进行商业上的利用，包括但不限于发布研究报告、提供信息咨询、开发产品和服务；
                        （3）有权通过本应用向您推送包括软件更新、功能增减、推荐广告等在内的信息。除本应用页面显示的信息外，
							贵司有权选择不接受其他推送信息，但由此可能导致的服务功能和服务体验的下降。 </p>
                        <p>5.4在本协议的有效期间内，贵司在此授权本公司在本公司的营销材料、公开网站上使用贵司的名称和图标。</p>
                        <p>5.5 除另有协议约定外，本公司为贵司提供的数据保存期限为12个月，超过12个月的数据将自动删除，
							由此产生任何法律责任或经济损失，本公司不承担责任。本公司若延长数据保存期限，不再另行通知。</p>
                        <h3>六、 知识产权</h3>
                        <p>6.1本公司拥有并保留，包括与本应用相关的所有知识产权，以及贵司与本应用之间的交易数据。</p>
                        <p>6.2在协议有效期间，本应用可能随时对本应用的各项服务进行开发，创作出新的自定义文件、设计、计算机程序、
                        计算机文档和其他有形材料（统称“成果”）。本应用对成果保留所有权利和利益。本应用在此授予贵司在服务期对成果有限的、
							不可转让的、不可再授权、非独占性的使用许可。</p>
                        <h3>七、 第三方服务</h3>
                        <p>7.1若贵司通过本应用所采购的特定产品或服务是由第三方商家提供的，则产品的退、换、技术支持以及质保期等按照第三方商
							家的政策执行。因第三方商家提供的产品和服务引起的争议与纠纷，由第三方商家解决并承担相关责任。</p>
                        <h3>八、 责任与免责</h3>
                        <p>8.1在与本协议有关的活动中，双方均应遵守适用的法律。任何以非法的方式使用本应用，或者将本应用用于非法目的的一方，
							应当独立承担相关的法律责任和后果。</p>
                        <p>8.2贵司明确知晓互联网技术的特点，并不能确保存储、生成于本应用中的数据能够百分之百安全且完整，贵司应当定期进行数据备份。
                        因如下所列任何原因而造成的贵司资料泄露、丢失、被盗用或被篡改等，本应用将获得免责： （1）任何因政府管制而造成的暂时
                        性关闭等影响网络正常浏览的不可抗力原因； （2）任何计算机系统问题、黑客攻击、计算机病毒侵入或发作的原因；
							（3）其他本应用尽了合理注意义务但不可预料的原因。 </p>
                        <p>8.3贵司有义务非常谨慎地保管自己的用户名及密码。本公司对贵司的用户名和密码的遗失或被盗产生的后果不承担任何责任。
							凡使用该用户名和密码的行为，视为贵司的操作。贵司对利用该用户名和密码所进行的一切活动负全部责任。</p>
                        <p>8.4本应用并非专门为处理劳动争议纠纷或留存证据而开发，对于贵司或者贵司的员工利用本应用储存、传输和处理的数据解决劳动
							争议纠纷的结果和效果，本公司不承担任何保证和责任。 </p>
                        <p>8.5本应用的各项服务中可能含有第三方网页的链接，本应用不对由第三方网页的安全性、网页信息的真实性和完整性负任何责任。 </p>
                        <h3>九、 适用法律</h3>
                        <p>9.1本协议受中华人民共和国（不含港澳台地区）法律管辖并依据中华人民共和国（不含港澳台地区）法律进行解释。</p>
                        <h3>十、 其他</h3>
                        <p>10.1本应用将视向用户所提供服务内容之特性，要求用户在注册本应用提供的有关服务时，遵守特定的条件和条款；
							如该特定条件和条款与以上本协议有任何不一致之处，则以特定条件和条款为准。</p>
                        <p>10.2本协议中的某一条款无论因何种原因被认定为无效或不具有执行力，不影响其余条款仍应有效并且有约束力。 </p>
                        <p>10.3本协议执行过程中所产生的任何问题，本应用和用户都将友好协商解决。协商不成时，任何一方均可向本公司注册
							所在地的人民法院提起诉讼。</p>
                        <p>10.4本协议的解释权归本公司最终所有，自贵司在线点击“同意”之日生效。（正文完）</p>


                        <h2>杭州仰正网络科技有限公司</h2>




                    </Modal>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer>
            </Layout>
        )
    }
}

export default Register