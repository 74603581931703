import * as types from '../mutation-types'
import { message } from 'antd'
import { push } from 'react-router-redux'
import { fetchGet, fetchPost, fetchPut } from '../../util/http'
import { loginOut } from './loginAction'
import Cookies from 'js-cookie'


/**
 * 新增企业
 * @param {*} params 
 */
export function addCompany(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/company/add', dispatch, params)
    }
}
/**
 * 删除企业
 * @param {*} params 
 */
export function deleteCompany(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/company/delCompany', dispatch, params)
    }
}
/**
 * 删除工资信息
 * @param {*} params 
 */
export function deleteWage(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/salary/delWage', dispatch, params)   
    }
}
/**
 * 忘记密码
 * @param {*} params 
 */
export function forgotPassword(params) {
    return async function (dispatch, getState) {
        fetchPost('/api/sys/forgetPwd', dispatch, params)
        .then(json => {
            if (json.success) {
                message.success('修改密码成功，请登录')
                dispatch(push('/login'))
            }
        })
    }
}
/**
 * 获取验证码
 */
export function getCode(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/sys/getCode', dispatch, params)
            .then(json => {
                if (json.success) {
                    message.success('验证码发送成功')
                }
                return Promise.resolve(json)
            })
    }
}
/**
 * 获取拥有的企业
 */
export function getCompanys() {
    return async function (dispatch, getState) {
        const { userId, companyName } = getState()['loginReducer']
        const params = {
            uid:userId,
        }
        return fetchGet('/api/company/get', dispatch, params)
            .then(json => {
                if (json.success) {
                    dispatch({
                        type: types.CHANGE_COMPANYS,
                        companys: json.data,
                    })
                    if (!companyName && json.data.length > 0) {
                        dispatch({
                            type: types.CHANGE_COMPANYNAME,
                            companyName: json.data[0].companyName,
                            companyId: json.data[0].id

                        })
                    }
                }
                return Promise.resolve(json)
            })
    }
}
/**
 * 查询某月的发送详情
 * @param {*} params 
 */
export function getUserListByMonth(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/salary/getUserListByMonth', dispatch, params)
    }
}
/**
 * 修改企业名称
 * @param {*} params 
 */
export function updateCompanyName(params) {
    return async function (dispatch, getState) {
        const { companyName } = getState()['loginReducer']
        return fetchPost('/api/company/updateCompanyName', dispatch, params)
        .then(json => {
            if (json.success) {
                message.success('修改成功') 
                dispatch({
                    type: types.CHANGE_COMPANYS,
                    companys: json.data,
                })
                if (companyName && companyName === params.oldName) {
                    dispatch({
                        type: types.CHANGE_COMPANYNAME,
                        companyName: params.newName
                    })
                }
            }
            return Promise.resolve(json)
        })
    }
}

/**
 * 修改为可见
 * @param {*} params 
 */
export function updateWageVisible(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/salary/updateWageVisible', dispatch, params)
    }
}
/**
 * 检查某月是否已存在数据
 * @param {*} params 
 */
export function checkWage(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/salary/checkWage', dispatch, params)
    }
}
/**
 * 解析文件
 * @param {*} params 
 */
export function resolveExcel(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/salary/resolveExcel', dispatch, params)
    }
}
/**
 * 修改密码
 * @param {*} params 
 */
export function updatePassword(params) {
    return async function (dispatch, getState) {
        fetchPost('/api/sys/updatePwd', dispatch, params)
            .then(json => {
                if (json.success) {
                    message.success('修改密码成功，请重新登录')
                    dispatch(loginOut())
                    return 'sucessful update'
                }
            })
    }
}
/**
 * 修改手机号
 * @param {*} params 
 */
export function updatePhone(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/sys/updatePhone', dispatch, params)
           
    }
}
/**
 * 获取用户列表
 * @param {*} params 
 */
export function getUserList(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/admin/user/list', dispatch, params)
    }
}
/**
 * 获取用户信息
 * @param {*} params 
 */
export function getUserInfo(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/sys/info', dispatch, params)
            .then(json => {
                if (json.success) {
                    
                    dispatch({
                        type: types.GET_USER_INFO,
                        data: json.data
                    })
                    return Promise.resolve(json)
                }
            })
    }
}

/**
 * 创建订单
 * @param {*} params 
 */
export function creatOrder(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/order/create', dispatch, params)

    }
}
/**
 * 更新用户
 * @param {*} params 
 */
export function updateUser(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/admin/user/update', dispatch, params)

    }
}
/**
 * 订单列表
 * @param {*} params 
 */
export function getOrderList(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/admin/order/list', dispatch, params)
    }
}
/**
 * 审批订单
 * @param {*} params 
 */
export function checkOrder(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/admin/order/check', dispatch, params)

    }
}
/**
 * 我的历史订单
 * @param {*} params 
 */
export function getPayHistory(params) {
    return async function (dispatch, getState) {
        return fetchGet('/api/order/history', dispatch, params)
    }
}
/**
 * 校验验证码
 * @param {*} params 
 */
export function checkPhoneCode(params) {
    return async function (dispatch, getState) {
        return fetchPost('/api/sys/checkCode', dispatch, params)

    }
}
