import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Table, Card, Badge, Modal,Form, Input, Select, Button, Space, message } from 'antd'
import moment from 'moment'
import { getOrderList, checkOrder } from '../redux/action/applicationAction'

const { Option } = Select;
const { TextArea } = Input;
const patModeType = {
    0: '线上支付',
    1:'银行转账'
}
const userType = {
    0: '按年付费',
    1: '永久免费',
    2: '一次性付费',
    3: '试用'
}
const checkStatusMap = {
    0: {
        text: '待审核', status: 'processing'
    },
    1: { text: '审核成功', status: 'success' },
    
    2: { text: '审核失败', status:'error'}
}
const orderStatusMap = {
    10: '未付款',
    20: '已付款',
    30: '已退款',
    40: '已取消'
}


@connect(state => state)
class orderList extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 1,
            pageSize: 10,
            total: 0,
            phone: '',
            status: '',
            visible: false,
            reason: '',
            orderId: null,
            loading: false
        }
    }
    componentDidMount() {
        this.fetchOrderList()
    }
    fetchOrderList() {
        const { dispatch } = this.props
        const { page, pageSize, phone, status } = this.state
        const params = {
            pageNum: page,
            pageSize: pageSize,
            phone: phone ? phone : '',
            status: status > -1 ? status: ''
        }
        this.setState({
            loading:true,
        })
        dispatch(getOrderList(params))
        .then(json => {
            if (json.success) {
                this.setState({
                    data: json.data.rows,
                    total: json.data.total,
                    loading:false
                })
            }
            
        })
    }
    onChangePage = (page) => {
        this.setState({
            page: page
        }, () => {
            this.fetchOrderList()
        })
    }
    onReset = () => {
        this.formRef.current.resetFields();
        this.setState({
            phone: '',
            status:''
        }, () => {
            this.fetchOrderList()
        })
        
    };
    onFinish = (values) => {
        console.log(values);
        this.setState({
            phone: values.phone,
            status: values.status,
            page:1,
        }, () => {
            this.fetchOrderList()
        })
    };
    showCheck = (id) => {
        this.setState({
            visible: true,
            orderId:id,
        })
    }
    closeModal = () => {
        this.setState({
            visible: false,
            reason: '',
            orderId:null,
        })
    }
    onOk = () => {
        const { reason, orderId } = this.state
        if (!reason) {
            message.error('请输入拒绝理由')
            return
        }
        const { dispatch } = this.props

        const params = {
            accept: 2,
            orderId: orderId,
            message:reason,
        }
        dispatch(checkOrder(params))
            .then(json => {
                if (json.success) {
                    message.success('操作成功')
                    this.setState({
                        visible:false
                    })
                    this.fetchOrderList()
                }
            })

    }
    onChangeReason = (e) => {
  
        this.setState({
            reason:e.target.value
        })
    }
    acceptOrder = (id) => {
        const { dispatch } = this.props

        const params = {
            accept: 1,
            orderId:id
        }
        dispatch(checkOrder(params))
            .then(json => {
                if (json.success) {
                    message.success('操作成功')
                    this.fetchOrderList()
                }
        })
    }

    render() {
        const { data, page, pageSize, total, visible, loading } = this.state
        const paginationProps = {
            current: page,
            pageSize: pageSize,
            total: total,
            showTotal: (total, range) => `共 ${total} 条`,
            onChange: this.onChangePage
        }
        const columns = [
            {
                title: '订单编号',
                dataIndex: 'orderId',
                // width: 100,
                // ellipsis:true,
            },
            {
                title: '手机号',
                dataIndex: 'userName',
                // width: 130,
            },
            {
                title: '付款时间',
                dataIndex: 'createTime',
                // width:200,
                render: (text, record) => {
                    return <span>{moment(text).format('YYYY/MM/DD HH:mm:ss')}</span>
                }
            },
            {
                title: '付款金额',
                dataIndex: 'payAmount'
            },
            {
                title: '付款方式',
                dataIndex: 'payMode',
                render: (text, record) => {
                    return <span>{patModeType[text]}</span>
                }
            },
            {
                title: '用户类型',
                dataIndex: 'type',
                render: (text, record) => {
                    return <span>{userType[text]}</span>
                }
            },
            {
                title: '订单状态',
                dataIndex: 'orderStatus',
                render: (text, record) => {
                    return <span>{orderStatusMap[text]}</span>
                }
            },
            {
                title: '审核状态',
                dataIndex: 'checkStatus',
                render: (text, record) => {
                    return <Badge status={checkStatusMap[text].status} text={checkStatusMap[text].text} />
                }
            },

            {
                title: '备注',
                dataIndex: 'sellerMessage'
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => {
                    if (record.checkStatus === 0) {
                        return <Space><Button type='primary' size='small' onClick={this.acceptOrder.bind(this,record.orderId)}>通过</Button>
                            <Button type='primary' danger size='small' onClick={this.showCheck.bind(this, record.orderId)}>拒绝</Button></Space>
                            
                    }
                }
            },
        ]
        return (
            <div>
                <Card title='订单管理'>
                    <Form
                        layout='inline'
                        style={{ marginBottom: 24 }}
                        ref={this.formRef}
                        name="control-ref"
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name='phone'
                            label='手机号'
                        >
                            <Input style={{width:250}} placeholder='请输入手机号' />
                        </Form.Item>
                        <Form.Item
                            name='status'
                            label='审核状态'
                        >
                            <Select
                                placeholder="请选择审核状态"
                                allowClear
                                style={{ width: 250 }}
                            >
                                <Option key='0' value={0}>待审核</Option>
                                <Option key='1' value={1}>审核成功</Option>
                                <Option key='2' value={2}>审核失败</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button type='primary' htmlType="submit">查询</Button>
                            <Button type="link" htmlType="button" onClick={this.onReset}>
                                清空
                            </Button>
                        </Form.Item>
                    </Form>
                    <Table
                        rowKey='orderId'
                        dataSource={data}
                        columns={columns}
                        pagination={paginationProps}
                        loading={loading}
                        scroll={{x:true}}
                    />
                        
                    
                </Card>
                <Modal
                    title='审核订单'
                    visible={visible}
                    onCancel={this.closeModal}
                    onOk={this.onOk}
                >
                    <h4>拒绝理由：</h4>
                    <TextArea rows={4} onChange={this.onChangeReason} />
                </Modal>
            </div>
        )
    }
}

export default orderList;
