import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { BrowserRouter as Router, Route, Link, Redirect, NavLink } from 'react-router-dom'
import {  Layout, Card,  Menu,  Tabs, Row, Col } from 'antd'
import jiantouImg from '../asstes/images/jiantou.png'
import xiaolv from "../asstes/images/icon-05.png";
import fudan from "../asstes/images/icon-06.png";
import qiye from "../asstes/images/icon-07.png";
import suishi from "../asstes/images/icon-08.png";
import anquan from "../asstes/images/icon-09.png";
import yuangong from "../asstes/images/icon-10.png";

const TabPane = Tabs.TabPane;
const { Header, Content, Footer } = Layout

export default class Introduce extends Component {
    render() {
        const leftCardTitle = (
            <div>
                <img src={qiye} width='64px'></img>
                <span style={{ fontSize: 20, marginLeft: '10px' }}>企业用户</span>
            </div>
        )
        const rightCardTitle = (
            <div>
                <img src={yuangong} width='64px'></img>
                <span style={{ fontSize: 20, marginLeft: '10px' }}>员工用户</span>
            </div>
        )
        return (
            <Layout>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo">
                    </div>

                    <Menu
                        // theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['introduce']}
                        style={{ lineHeight: '64px', height: '64px' }}
                    >
                        <Menu.Item key="login"><NavLink to='/login'>首页</NavLink></Menu.Item>
                        <Menu.Item key="introduce"><NavLink to='/introduce'>服务介绍</NavLink></Menu.Item>
                        <Menu.Item key="about"><NavLink to='/about'>关于我们</NavLink></Menu.Item>
                    </Menu>
                </Header>


                <Content style={{ marginTop: 64, }} className='introContent'>
                    <div className='introContainer'>
                        <p className='header'>i工资条服务由杭州仰正网络科技有限公司提供技术和运营支持。</p>
                        <p className='header'>仰正科技，专注于用小而美的软件服务，大幅提高企业在人事、财务等工作的管理效率。</p>
                        <div className='title'>
                            <h1>i工资条使用流程</h1>
                            <img src={jiantouImg} width='100%'></img>
                        </div>


                        <Row>
                            <Col span={10} >
                                <Card
                                    title={leftCardTitle}
                                >
                                    <div className='content'>
                                        <div className='icon'>
                                            <img src={xiaolv} /> <div className='smallTitle'>高效率</div>
                                        </div>
                                        <div className='text'>
                                            <p >不限制工资条格式，管理员只须将已有工资条上传即可，不新增任何工作量。 </p>
                                            <p >操作简单，一键即可向员工发送工资条，实时达到员工。</p>
                                            <p >可便捷实现工资条撤回或覆盖、多企业账户管理等个性化需求。</p>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='icon' >
                                            <img src={fudan} /> <div className='smallTitle'>低负担</div>
                                        </div>
                                        <div className='text'>
                                            <p >工资条服务每年仅需99元起！</p>
                                            <p >HR无须安装任何软件或插件，不增加办工设备负荷。</p>
                                        </div>
                                    </div>
                                </Card>


                            </Col>
                            <Col span={10} offset={4}>
                                <Card
                                    title={rightCardTitle}
                                >
                                    <div className='content'>
                                        <div className='icon'>
                                            <img src={suishi} /> <div className='smallTitle'>随时查</div>
                                        </div>
                                        <div className='text'>
                                            <p >顺应用户移动设备的使用习惯，微信公众号随时接收、查询工资信息。 </p>
                                            <p >保存历史工资条信息达12个月，随时对比工资变化情况，助力职场成长。</p>
                                            <p >随时随地查，不错过工资勘误反馈的最佳时机。</p>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='icon' >
                                            <img src={anquan} /> <div className='smallTitle'>更安全</div>
                                        </div>
                                        <div className='text'>
                                            <p >数据经服务器多重加密后传输，保障工资等隐私信息的网络安全。</p>
                                            <p >一人一手机号对应一特定工资条初始密码，进一步防止工资条泄露隐患。</p>
                                            <p >不需要姓名、身份证等敏感信息，即可发送工资条，更有利于保护员工隐私。</p>
                                        </div>
                                    </div>
                                </Card>

                            </Col>
                        </Row>

                    </div>

                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer>
            </Layout>
        )
    }
}