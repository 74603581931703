import { push } from 'react-router-redux'
import { message } from 'antd';
import Cookies from 'js-cookie'




function checkHttpStatus(response, dispatch) {
    if (response.status >= 200 && response.status < 300) {

        return response.json();
    } else if (response.status === 403) {
        message.error(`${response.status}, 没有权限！`)
        // dispatch(push('/err403'))
        return false
    } else if (response.status === 401) {
        message.error(`${response.status},登录已过期`)
        Cookies.remove('isLogined')
        Cookies.remove('token')
        dispatch(push('/login'))
        return false

    } else if (response.status === 404) {
        message.error(`${response.status},请求失败 `)
        // dispatch(push('/err404'))
        return false
    } else if (response.status === 500) {
        message.error(`${response.status}, 服务器错误`)
        // dispatch(push('/err500'))
        return false
    } else {
        message.error(`${response.status}, 未知错误`)
        // dispatch(push('/err500'))
        return false
    }
}

function formatWeekDay(weekDayArr) {
    let string = ''
    const dayMap = { 1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日', }
    if (weekDayArr) {
        if (weekDayArr.every(item => item === 1) || weekDayArr.every(item => item === 0)) {
            string = '每天'
        } else if (weekDayArr.slice(0, 5).every(item => item === 1) && weekDayArr.slice(5,).every(item => item === 0)) {
            string = '工作日'
        } else {
            let arr = []
            weekDayArr.forEach((item, index) => {
                if (item > 0) {
                    arr.push(dayMap[index + 1])
                }
            });
            string = arr.join('，')
        }
    }
    
    return string
}
function weekdayToArr(weekDayArr, type) {
    let arr = []
    if (weekDayArr) {
        if (type === 'toNumber') { //1和0将数组转换为周几
            weekDayArr.forEach((item, index) => {
                if (item > 0) {
                    arr.push(index + 1)
                }
            })
        } else if (type === 'toList') {// 周几转换为7个数字的lsit
            for (let i = 0; i < 7; i++) {
                if (weekDayArr.includes(i + 1)) {
                    arr.push(1)
                } else {
                    arr.push(0)
                }
            }
        }
    }
    return arr
}

export { checkHttpStatus, formatWeekDay, weekdayToArr }