import React, { Component } from 'react'
import Q5 from '../asstes/images/Q5.png'
import Q6 from '../asstes/images/Q6.png'
import Q10 from '../asstes/images/Q10.jpg'
import Q101 from '../asstes/images/Q10-1.png'


export default class HelpCenter extends Component {
    render() {
        return (
            <div className='helpContainer'>
                <h1>常见问题</h1>
                <h3><span>Q：</span>数据安全吗？</h3>
                <p><span>A：</span>必须的！有阿里云服务器多重加密，请放心~</p>

                <h3><span>Q：</span>能试用多久？</h3>
                <p><span>A：</span>试用2个月，2个月后未付费的，后台将删除账号信息，员工也将无法查询工资条数据哦~</p>

                <h3><span>Q：</span>有人数限制吗？</h3>
                <p><span>A：</span>无人数上限，人数不同收费会有所不同哟~</p>

                <h3><span>Q：</span>员工需要注册吗？</h3>
                <p><span>A：</span>不需要哈~不需要哈~不需要哈~重要的事情说3遍哟~如员工有误注册的，请联系客服QQ1987960997或是微信公众号私信留言注销账号。</p>

                <h3><span>Q：</span>员工如何查收？</h3>
                <p><span>A：</span>HR上传工资表并发送成功后，平台会给员工发送初始密码，关注微信公众号“财华出众”，点击底部菜单栏“i工资条”，员工凭手机号码及初始密码即可查询~初始密码仅第一次发送哟，请HR提醒员工修改查询密码哈~</p>
                <img src={Q5} width='80%' />
                <h3><span>Q：</span>员工查收显示无数据或是账号不存在的常见原因：</h3>
                <p><span>A：</span>其一：HR第一次发送成功后，发现错误重新上传，在HR重新上传的过程中，前一次的数据已被清空，致使员工在此间隙查询时，数据是空的，等到HR重新上传并发送成功后，员工便又可查询数据~</p>
                <p>其二：HR第一次发送成功后，发现错误重新上传，一紧张，忘记点“立即发送”，导致员工端数据为空。切记要点击“立即发送”！</p>
                <p>其三：HR第一次发送成功后，发现错误重新上传，仅上传了修改的那几条，数据被全覆盖为最后一次上传的若干条数据，其他员工查询便显示无数据。切记，第二次乃至第N次重新上传，不是追加是全覆盖哈，每一次重新上传都需要传完整版数据哦！</p>
                <p>其四：有多家公司或多个部门，需分开上传，上传在同一个企业账号里，把前一次上传的数据覆盖了，请记得“切换企业”哟~</p>
                <img src={Q6} width='80%' />
                <h3><span>Q：</span>工资表发送成功了，为什么有些员工查询时显示账号不存在呢？</h3>
                <p><span>A：</span>其一：上传工资表里，漏填该员工手机号码，填上后重新上传即可~</p>
                <p>其二：上传工资表里，该员工对应的手机号码存在换行，导致系统识别不到手机号码，默认该条不上传，把换行去掉重新上传即可~</p>
                <p>其三：该员工的手机号码被误列入黑名单，平台无法给该员工发送初始密码，该员工需去通讯公司解除黑名单，重新上传即可~</p>
                <h3><span>Q：</span>工资表一直显示上传不成功？</h3>
                <p><span>A：</span>检查下工资表是否加密，如有加密，工资表则无法上传哦~</p>
                <h3><span>Q：</span>试用结束了，如何收费？</h3>
                <p><span>A：</span>每年99元起，详询客服QQ：1987960997</p>
                <h3><span>Q：</span>收费账号</h3>
                <p><span>A：</span>支持银行转账、支付宝收款、微信收款，亦可在官方淘宝店拍下相应产品。付款时请备注登录/注册手机号码，如忘记备注，请加客服QQ1987960997，发送付款截图并留言手机号码~</p>
                <h4>收款信息如下：</h4>
                <p>银行收款信息</p>
                <p>名称：杭州仰正网络科技有限公司</p>
                <p>银行：浙江民泰商业银行股份有限公司杭州分行</p>
                <p>账号：583851312700015</p>

                <p>支付宝收款信息</p>
                <p>名称：杭州仰正网络科技有限公司</p>
                <p>账号：1987960997@qq.com</p>
                <p>二维码：</p>
                <img src={Q10} width={200} />
                <p>官方淘宝店链接：</p>
                <p>https://item.taobao.com/item.htm?ft=t&id=599428228304 </p>
                <p>或打开手机淘宝APP，扫一扫下方二维码即可获得链接。</p>
                <img src={Q101} width={200} />
                <h3><span>Q：</span>能开发票吗？</h3>
                <p><span>A：</span>能开电子发票哈，请亲在订单里备注邮箱信息哟~</p>
                <h3><span>Q：</span>一个账号里添加多家企业/部门，需要加费吗？</h3>
                <p><span>A：</span>不需要哈，仅按总人数分档收费哦，不限一个账号下的企业/部门数量。</p>
                <h3><span>Q：</span>一个年度里，因公司规模扩大，总人数上了一个台阶，年中需要调整收费吗？</h3>
                <p><span>A：</span>不需要哈，待下一年续费时再调整年度收费，我们要做长期的伙伴，不在乎朝朝暮暮，在乎长长久久，但是要诚信哦。</p>
                <h3><span>Q：</span>员工查收工资条能嫁接到自己公司的微信公众号上吗？</h3>
                <p><span>A：</span>可以哈，在标准收费上每年再加300元哦~</p>
            </div>
        )
    }
}