
import * as types from '../mutation-types'
import Cookies from 'js-cookie'

const initialState = {
    currentRole: null,
    clickedLogout: false,//点击退出登录
    hasChanged:false, //已编辑但未保存

}


const mutations = {
    
    
}





export default function applicationReducer(state = initialState, action) {
    if (!mutations[action.type]) return state;
    return { ...state, ...mutations[action.type](state, action) }
}