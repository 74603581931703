import { message } from 'antd'
import { push, replace} from 'react-router-redux'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import {  baseUrl } from '../../util/config'
import { fetchGet, fetchPost, fetchPut } from '../../util/http'
import { checkHttpStatus } from '../../util/publicFunc'
import moment from 'moment'


export function accountLogin(params) {
    return async function (dispatch, getState) {
        return await fetch(`${baseUrl}/api/sys/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })
        .then(response =>
            checkHttpStatus(response, dispatch)
        )
        .then(json => {
            if (json.success) {
                Cookies.set('isLogined', 'true',)
                Cookies.set('token', json.data.token,)
                Cookies.set('userId', json.data.uid,)
                Cookies.set('userName', json.data.userName,)
                Cookies.set('adminTag', json.data.adminTag)
                console.log('登录成功')
                dispatch({
                    type: types.LOGIN_IN,
                    data:json.data,
                    // token: json.data.token,
                    // userId: json.data.uid,
                    // userName: json.data.userName,
                    // companys: json.data.companys,
                    // companyName: json.data.companys.length > 0 ? json.data.companys[0].companyName : '',
                    // companyId: json.data.companys.length > 0 ? json.data.companys[0].id : '',
                    // validTime: moment(json.data.validTime).format('YYYY-MM-DD'),
                    // adminTag: json.data.adminTag,
                    // salePrice: json.data.salePrice,
                    // type: json.data.type
                })
                if (json.data.companys.length === 0) {
                    dispatch(push('/manage'))

                } else {
                    dispatch(push('/new'))
                }
                
                
            } else {
                message.error(json.message)
            }
        })
    }
}

export function loginOut() { 
    return async function (dispatch, getState) {
        Cookies.remove('isLogined')
        Cookies.remove('token')
        Cookies.remove('userId')
        Cookies.remove('userName')
        Cookies.remove('adminTag')
        dispatch({
            type: types.LOGIN_OUT,
        })

        window.onbeforeunload = () => null;
        window.location.reload()
    }
}
/**
 * 注册
 * @param {*} params 
 */
export function accountRegister(params) {
    return async function (dispatch, getState) {
        fetchPost('/api/sys/register', dispatch, params)
            .then(json => {
                if (json.success) {
                    message.success('注册成功，请登录')
                    dispatch(push('/login'))
                }
            })
    }
}
export function changeCompany(data) {
    return function (dispatch) {
        dispatch({
            type: types.CHANGE_COMPANYNAME,
            companyName: data.companyName,
            companyId: data.id,
        })
    }
}