import Login from '../pages/login'
import History from '../pages/history'
import HelpCenter from '../pages/help'
import Forgot from '../pages/forgot'
import ManageCompany from '../pages/manageCompany'
import New from '../pages/new'
import Register from '../pages/register'
import Introduce from '../pages/introduce'
import About from '../pages/about'
import UserInfo from '../pages/userInfo'
import Pay from '../pages/pay'
import PayHistory from '../pages/payHistory'
import OrderList from '../pages/orderList'
import UserList from '../pages/userList'



const routes = [
    {
        path: '/',
        exact: true,
        component: New,
    },
    {
        path: '/new',
        exact: true,
        component: New,
    },
    {
        path: '/history',
        component: History
    },
    {
        path: '/help',
        component: HelpCenter
    },
    {
        path: '/forgot',
        component: Forgot
    },
    {
        path: '/manage',
        component: ManageCompany,
    },
    {
        path: '/userInfo',
        component: UserInfo,
    },
    {
        path: '/pay',
        component:Pay,
    },
    {
        path: '/payHistory',
        component: PayHistory,
    },
    {
        path: '/orderList',
        component: OrderList
    },
    {
        path: '/userList',
        component:UserList
    }

]



const loginRoutes = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/register',
        component: Register,
    },
    {
        path: '/forgot',
        component: Forgot,
    },
    {
        path: '/introduce',
        component: Introduce,
    },
    {
        path: '/about',
        component: About
    }
]

const selectedKeysMap = {
    '/new': 'new',
    '/history': 'history',
    '/help': 'help',
    '/manage': 'manage',
    '/userInfo': 'userInfo',
    '/pay': 'pay',
    '/payHistory': 'payHistory',
    '/userList': 'userList',
    '/orderList':'orderList'
}


export { routes, loginRoutes, selectedKeysMap }