import { message } from 'antd';
import Cookies from 'js-cookie'
import { baseUrl } from "./config";
import { checkHttpStatus } from './publicFunc'
import { loginOut } from '../redux/action/loginAction'




const obj2String = (obj, arr = [], idx = 0) => {
    if (obj) {
        for (let item in obj) {
            arr[idx++] = [item, obj[item]]
        }
        return new URLSearchParams(arr).toString()
    } else {
        return ''
    }
    
}


/**
 * 
 * @param {请求地址} url
 * @param {请求参数} params
 * @param {请求方式} method
 */
async function commonFetch(url, dispatch, params, method = 'GET') {

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': Cookies.get('token'),
    }
    let fetchOptions = {}
    if (method === 'GET') {
        if (params) {
            url = url + '?' + obj2String(params)
        }
        fetchOptions = {
            method: method,
            headers:headers,
        }
    } else {
        if (params) {
            fetchOptions = {
                method: method,
                headers: headers,
                body: JSON.stringify(params)
            }
        } else {
            fetchOptions = {
                method: method,
                headers: headers,
            }
        }
        
    }
    return await fetch(`${baseUrl}`+ url, fetchOptions)
    .then(response => checkHttpStatus(response))
    .then(json => {
        if (json.code == 401) {
            message.error('登录已过期，请重新登录')
            dispatch(loginOut())
            return
        }
        if (json.success) {
            return Promise.resolve(json)
        } else {
            if (json) {
                message.error(json.message)
            }
            return Promise.resolve(json)
        }
    })
}

function fetchGet(url, dispatch, params,) {
    return commonFetch(url, dispatch,params,'GET')
}
function fetchPost(url, dispatch,params,){
    return commonFetch(url, dispatch,params, 'POST')
}
function fetchPut(url, dispatch, params,){
    return commonFetch(url, dispatch, params, 'PUT')
}
function fetchDelete(url, dispatch, params,) {
    return commonFetch(url, dispatch, params, 'DELETE')
}



export { fetchGet, fetchPost, fetchPut, fetchDelete }




