
const envir = 'online'

const configMap = {
    //开发环境
    dev: 'http://hl99.helianwap.com',
    //线上环境
    online: 'http://47.110.137.4:8080',
}

const baseUrl = configMap[envir]

export { baseUrl, }