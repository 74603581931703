import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { BrowserRouter as Router, Route, Link, Redirect, NavLink } from 'react-router-dom'
import {  Layout,  Menu,  Tabs, } from 'antd'
import mapImg from '../asstes/images/map.png'
import ewm from '../asstes/images/ewm.jpg'
import taobao1 from '../asstes/images/taobao1.png'
import taobao2 from '../asstes/images/taobao2.png'

const TabPane = Tabs.TabPane;
const { Header, Content, Footer } = Layout

export default class About extends Component {
    render() {
        return (
            <Layout style={{ background: 'rgba(170, 245, 238, 0.4)' }}>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo">
                    </div>

                    <Menu
                        // theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['about']}
                        style={{ lineHeight: '64px', height: '64px' }}
                    >
                        <Menu.Item key="login"><NavLink to='/login'>首页</NavLink></Menu.Item>
                        <Menu.Item key="introduce"><NavLink to='/introduce'>服务介绍</NavLink></Menu.Item>
                        <Menu.Item key="about"><NavLink to='/about'>关于我们</NavLink></Menu.Item>
                    </Menu>
                </Header>


                <Content style={{ padding: '0 50px', marginTop: 64, }} className='aboutContent'>
                    <div className='mapContainer'>
                        <div>
                            <h3>关于我们</h3>
                            <p>i工资条，隶属于杭州仰正网络科技有限公司，为中小企业提供方便、快捷、高效的一键群发工资条服务，获得了客户一致好评。</p>
                            <img src={mapImg} style={{ width: 500, height: '80%' }}></img>
                        </div>
                        <div className='textBox'>
                            <h3>联系我们</h3>
                            <p>客服QQ：1987960997</p>
                            <p>微信公众号：财华出众</p>
                            <img src={ewm} style={{ width: '50%' }}></img>
                            <p style={{ marginTop: 16 }}>淘宝店铺：</p>
                            <p>https://item.taobao.com/item.htm?ft=t&id=599428228304</p>
                            <div className='taobaoImg'>
                                <img src={taobao1} width={180} />
                                <img src={taobao2} width={180} height={180} />
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer>
            </Layout>
        )
    }
}