import React, { Component } from 'react'
import { Layout, Form, Input, Button, Menu } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import 'whatwg-fetch'
import {  accountLogin, } from '../redux/action/loginAction'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { BrowserRouter as Router, Route, Link, Redirect, NavLink } from 'react-router-dom'

import indexBg from '../asstes/images/indexBg.jpg'

const { Header, Content, Footer } = Layout
const FormItem = Form.Item

@connect(state => state)
class Login extends Component {

    handleSubmit = (values) => {
        const { dispatch } = this.props
        values.loginType = 1
        dispatch(accountLogin(values))

    };
    gotoRegister() {
        const { dispatch } = this.props
        dispatch(push('/register'))

    }
    gotoForgotPassword = () => {
        const { dispatch } = this.props
        dispatch(push('/forgot'))
    }

    render() {

        return (
            <Layout>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo">

                    </div>

                    <Menu
                        // theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['login']}
                        style={{ lineHeight: '64px', height: '64px' }}
                    >
                        <Menu.Item key="login"><NavLink to='/login'>首页</NavLink></Menu.Item>
                        <Menu.Item key="introduce"><NavLink to='/introduce'>服务介绍</NavLink></Menu.Item>
                        <Menu.Item key="about"><NavLink to='/about'>关于我们</NavLink></Menu.Item>
                    </Menu>
                </Header>
                <Content style={{ marginTop: 64, backgroundImage: "url(" + indexBg + ")", backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}>
                    <div className='homeContainer'>
                        <div className="login-content">
                            <Form
                                onFinish={this.handleSubmit}
                                className="login-form"
                                // initialValues={{ userName: '15957140817', userPassword:'123456'}}
                            >
                                <h1>请登录您的HR账号</h1>
                                <FormItem
                                    name="userName"
                                    rules={[{ required: true, message: '请输入手机号', }]}
                                >
                                    <Input prefix={<UserOutlined  />} placeholder="手机号" />
                                </FormItem>
                                <FormItem
                                    name="userPassword"
                                    rules={[{ required: true, message: '请输入密码', }]}
                                >
                                    <Input prefix={<LockOutlined />} type="password" placeholder="密码" />
                                </FormItem>
                                <FormItem>
                                    <a className="login-form-forgot" onClick={this.gotoForgotPassword}>忘记密码</a>
                                    <Button type="primary" htmlType="submit" style={{}} className="login-form-button">
                                        登录
									</Button>
                                </FormItem>
                                <p>没有账号？<a className="login-form-forgot" onClick={this.gotoRegister.bind(this)}>立即注册</a></p>
                            </Form>
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    版权所有 © 2019-2021  杭州仰正网络科技有限公司   浙ICP备19013797号
				</Footer>
            </Layout>
        )
    }
}

export default Login
