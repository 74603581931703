import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card,Table,  Modal, message, DatePicker, Empty } from 'antd'
import { getUserListByMonth, deleteWage } from '../redux/action/applicationAction'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { MonthPicker } = DatePicker;
const confirm = Modal.confirm

@connect(state => state)
class History extends Component {

    constructor(props) {
        super(props)
        this.state = {
            month: moment().subtract(1, 'months').format("YYYY-MM"),
            visible: false,
            payDatas: [],
            headerList: [],
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
        }
    }
    componentDidMount() {
        const { companyName } = this.props.loginReducer
        if (companyName.length > 0) {
            this.fetchWagesList(companyName)
        }
    }
    componentWillReceiveProps(nextProps) {
        let companyName1 = this.props.loginReducer.companyName
        let companyName2 = nextProps.loginReducer.companyName
        if (companyName1 !== companyName2) {
            this.fetchWagesList(companyName2)
        }
    }
    fetchWagesList(companyName) {
        const { dispatch } = this.props
        const {  month, page, pageSize } = this.state
        const paramsObj = {
            companyName: companyName,
            month: month,
            pageNum: page,
            pageSize: pageSize,
        }
        dispatch(getUserListByMonth(paramsObj))
            .then(json => {
                if (json.success) {
                    if (json.data) {
                        const headerList = Object.keys(json.data.payData.rows[0].excelData)
                        const payDatas = json.data.payData.rows.map(item => {
                            const obj = Object.assign(item.excelData, { id: item.id })
                            return obj
                        })
                        this.setState({
                            payDatas: payDatas,
                            headerList: headerList,
                            total: json.data.payData.total
                        })

                    } else {
                        this.setState({
                            payDatas: [],
                            headerList: []
                        })
                    }

                } else {
                    message.error(json.message)
                }


            })

    }

    deleteOne(record) {
        // console.log(record)
        const { dispatch } = this.props
        const { companyName } = this.props.loginReducer
        let _this = this
        const params = {
            id: record.id,
        }
        confirm({
            title: '你确认撤销该条数据?',
            content: '确认撤销后，该条数据将被删除，无法进行任何查询',
            cancelText: '取消',
            okText: '确定',
            onOk() {
                dispatch(deleteWage(params))
                    .then(json => {
                        if (json.success) {
                            message.success(json.message)
                            _this.fetchWagesList(companyName)
                        } else {
                            message.error(json.message)
                        }
                    })
            },
            onCancel() { },
        });
    }
    deleteAll() {
        const { dispatch } = this.props
        const { companyName } = this.props.loginReducer
        let _this = this
        const params = {
            companyName: companyName,
            month: this.state.month,
        }

        confirm({
            title: '你确认撤销该月所有数据?',
            content: '确认撤销后，该月数据将被删除，无法进行任何查询',
            cancelText: '取消',
            okText: '确定',
            onOk() {
                dispatch(deleteWage(params))
                    .then(json => {
                        if (json.success) {
                            _this.fetchWagesList(companyName)
                        } else {
                            message.error(json.message)
                        }
                    })
            },
            onCancel() { },
        });
    }
    TimeChange = (date, dateString) => {
        const { companyName } = this.props.loginReducer
        this.setState({
            month: dateString
        }, () => {
            this.fetchWagesList(companyName)
        })
    }
    handleOk = (e) => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    }

    handleCancel = (e) => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { companyName } = this.props.loginReducer
        this.setState({
            page: pagination.current,
            pageSize: pagination.pageSize,
        }, () => {
            this.fetchWagesList(companyName)
        })
    }



    render() {
        const { headerList, page, pageSize, total, loading } = this.state
        let scrollWidth = 0
        const columns = headerList.map((item, index) => {
            const obj = {
                title: item,
                dataIndex: item,
                // width: 150,
                align: 'center'
            }
            if (index === headerList.length - 1) {
                // delete obj['width']
            }
            // if (obj.width) {
            //     scrollWidth = scrollWidth + obj.width
            // }

            return obj
        })
        const actionCol = {
            title: <span>操作：{this.state.payDatas.length > 0 && <a href="javascript:;" onClick={this.deleteAll.bind(this,)}>全部撤销</a>}</span>,
            key: 'action',
            width: 150,
            align: 'center',
            render: (text, record) => (
                <span><a href="javascript:;" onClick={this.deleteOne.bind(this, record)}>撤销</a></span>
            )
        }
        // scrollWidth = scrollWidth + 150
        columns.push(actionCol)

        const paginationProps = {
            current: page,
            pageSize: pageSize,
            total: total,
            showTotal: (total, range) => `共 ${total} 条`,
        }
        return (
            <div className='historyContainer'>
                {/* <Tabs tabPosition='left'>
					{tabPaneArr}
				</Tabs> */}
                <Card title='发送记录'>

                
                    <MonthPicker
                        allowClear={false}
                        locale={locale}
                        defaultValue={moment().subtract(1, 'months')}
                        onChange={this.TimeChange} placeholder="请选择年月"
                        disabledDate={(current) => current > moment().endOf('day')}
                    /><span>本平台目前仅保留最近12个月的发送记录</span>
                    <div className='historyDetail'>
                        {headerList.length > 0 ?
                            <Table
                                columns={columns}
                                bordered
                                rowKey={record => record.id}
                                dataSource={this.state.payDatas}
                                scroll={{ x: true, y: 800 }}
                                pagination={paginationProps}
                                loading={loading}
                                onChange={this.handleTableChange}
                            />
                            :
                            <Empty />
                        }
                    </div>
                </Card>
            </div>
        )
    }
}
export default History