import * as types from '../mutation-types'
import Cookies from 'js-cookie'
import moment from 'moment'



const initialState = {
    isLogined: Cookies.get('isLogined') == 'true' ? true : false,
    token: Cookies.get('token'), // 用户身份标识
    userId: Cookies.get('userId'),//用户ID
    userName: Cookies.get('userName'), // 用户手机号
    adminTag: Cookies.get('adminTag'), //1管理员
    companyName: '', // 用户当前操作公司名称
    companyId: '',//用户当前操作公司ID
    companys: [],//该用户拥有的所有公司
    validTime:'',
    salePrice: '',
    type: 0, //类型0按年付费1永久免费2一次性付费3试用
    
    
}


const mutations = {
    [types.LOGIN_IN](state, payload) {
        // console.log(payload,'loginReducer')
        return Object.assign(
            {},
            state,
            {
                isLogined: true,
                token: payload.data.token,
                userId: payload.data.uid,
                userName: payload.data.userName,
                companys: payload.data.companys,
                companyName: payload.data.companys.length > 0 ? payload.data.companys[0].companyName : '',
                companyId: payload.data.companys.length > 0 ? payload.data.companys[0].id : '',
                validTime: moment(payload.data.validTime).format('YYYY-MM-DD'),
                adminTag: payload.data.adminTag,
                salePrice: payload.data.salePrice,
                type: payload.data.type
            }
        )
    },
    [types.LOGIN_OUT](state, payload) {
        return Object.assign({}, state,
            {
                isLogined: false,
                token: '',
                userName: '',
                companys: [],
                companyName: '',
                adminTag:null,
            })
    },
    [types.CHANGE_COMPANYNAME](state, payload) {
        return Object.assign({}, state,
            {
                companyName: payload.companyName,
                companyId: payload.companyId,
            })
    },
    [types.CHANGE_COMPANYS](state, payload) {
        return Object.assign({}, state,
            {
                companys: payload.companys,
            })
    },
    [types.GET_USER_INFO](state, payload) {
        return Object.assign({}, state,
            {
                validTime: moment(payload.data.validTime).format('YYYY-MM-DD'),
                salePrice: payload.data.salePrice,
                type: payload.data.type,
            })
    }
    
    
}


export default function loginReducer(state = initialState, action) {
    if (!mutations[action.type]) return state;
    return { ...state, ...mutations[action.type](state, action) }
}