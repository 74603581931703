import React, { Component } from 'react'
import { Modal,  Form,  Input,  } from 'antd'
import { updatePassword } from '../redux/action/applicationAction'
import { connect } from 'react-redux'

const FormItem = Form.Item

const defaultFormLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    }
}

@connect(state => state)
class UpdatePasswordModal extends Component {
    
    formRef = React.createRef();

    onOk = () => {
        const { dispatch } = this.props
        const { userName } = this.props.loginReducer

        this.formRef.current.validateFields().then((values) => {
            console.log(values)
            values.userName = userName
            values.loginType = 1
            dispatch(updatePassword(values))
            
        })
    }
    onCancel = () => {
        this.props.closeModal()
    }

    render() {
        const { visible } = this.props
        const pwdRule = "^[A-Za-z0-9]{6,16}$"
        return (
            <div className="modal">
                <Modal
                    title="修改密码"
                    wrapClassName="wy-center-modal"
                    visible={visible}
                    onOk={this.onOk}
                    onCancel={this.onCancel}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <Form className="wy-modal-form" {...defaultFormLayout} ref={this.formRef}>
                        <FormItem
                            label='原密码'
                            name="userPassword"
                            rules={[{ required: true, message: '请输入原密码', }]}
                        >
                            <Input.Password placeholder="请输入原密码" style={{width:280}} />
                        </FormItem>
                        <FormItem
                            label='新密码'
                            name="newPassword"
                            rules={[{ required: true, message: '请输入6-16位数字或字母', pattern: new RegExp(pwdRule) }]}
                        >
                            <Input.Password placeholder="请输入6-16位数字或字母" style={{ width: 280 }} />
                        </FormItem>
                        <FormItem
                            label="确认新密码"
                            name="again"
                            dependencies={['newPassword']}
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入新密码'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('密码两次输入不一致');
                                    }
                                })
                            ]}
                        >
                            <Input.Password type="password" placeholder="请再次输入新密码" style={{ width: 280 }} />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}
export default UpdatePasswordModal