import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
// import { routerReducer, } from 'react-router-redux'
import { routerMiddleware, connectRouter  } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import reducers from '../reducers'

const history = createBrowserHistory()
const historyMiddleware = routerMiddleware(history)
const createStoreWithMiddleware = applyMiddleware(historyMiddleware, thunk)(createStore)
const store = createStoreWithMiddleware(
    combineReducers({
        ...reducers,
        router: connectRouter(history),
    }),
    window.devToolsExtension && window.devToolsExtension()
)

export {
    store,
    history
}