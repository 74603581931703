import React, { Component } from 'react'
import { Layout, Form,  Modal,  message, Table, Tabs, Divider, Input, Card } from 'antd'
import 'whatwg-fetch'
import { deleteCompany, updateCompanyName, getCompanys } from '../redux/action/applicationAction'
import { connect } from 'react-redux'
import AddCompany from '../components/addCompany'


const { Header, Content, Footer } = Layout
const FormItem = Form.Item
const confirm = Modal.confirm
const TabPane = Tabs.TabPane;
const defaultFormLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    }
}
@connect(state => state)
class ManageCompany extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            oldName: ''
        }
    }
    componentDidMount() {
        this.fetchGetCompanys()
    }

    fetchGetCompanys() {
        const { dispatch } = this.props
        dispatch(getCompanys())
            .then(json => {
                if (json.success) {
                    this.setState({
                        data: json.data
                    })
                }
            })
    }
    deleteCompany(record) {
        const { dispatch } = this.props
        let _this = this
        const parmas = {
            companyName: record.companyName
        }
        confirm({
            title: '确定删除该企业?',
            content: '确认删除后，您将无法查看该企业的数据',
            cancelText: '取消',
            okText: '确定',
            onOk() {
                dispatch(deleteCompany(parmas))
                .then(json => {
                    if (json.success) {
                        message.success('删除成功')
                        _this.fetchGetCompanys()
                    } else {
                        message.error('操作失败')
                    }
                })
            },
            onCancel() { },
        });
    }
    editCompany = (record) => {
        this.setState({
            visible: true,
            oldName: record.companyName
        }, () => {
            console.log(this.state.oldName)
        })
    }
    submit = () => {
        const { oldName } = this.state

        const { dispatch } = this.props
        const { userName, } = this.props.loginReducer
        const values = this.formRef.current.getFieldsValue()
        const params = Object.assign(values, { oldName: oldName }, { userName: userName })
        dispatch(updateCompanyName(params))
        .then(json => {
            console.log(json)    
            if (json.success) {
                this.closeModal()
                this.fetchGetCompanys()
            }
        })
    }
    closeModal = () => {
        this.setState({
            visible: false,
            oldName: ''
        })
    }
    render() {
        const { companys, companyName } = this.props.loginReducer

        const columns = [
            {
                title: '企业名称',
                dataIndex: 'companyName',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => {
                    let cannotDelete = false
                    if (this.state.data.length <= 1) {
                        cannotDelete = true
                    } else {
                        if (record.companyName === companyName) {
                            cannotDelete = true
                        }
                    }
                    return (
                        <span>
                            <a onClick={this.deleteCompany.bind(this, record)} disabled={cannotDelete}>删除</a>
                            <Divider type="vertical" />
                            <a onClick={this.editCompany.bind(this, record)} >修改</a>
                        </span>
                    )

                }
            }
        ]

        return (
            <div className='manageContainer'>
                <Card>
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="添加新企业" key="1">
                            {companys.length == 0 && <p>第一次登录请先添加企业，否则无法上传文件。</p>}
                            <AddCompany />
                        </TabPane>
                        <TabPane tab="已管理企业" key="2">
                            <Table dataSource={this.state.data} columns={columns} rowKey='id' pagination={false} />
                        </TabPane>
                    </Tabs>
                </Card>
                
                <Modal
                    title='修改企业名称'
                    visible={this.state.visible}
                    onOk={this.submit}
                    onCancel={this.closeModal}
                    okText='提交'
                    cancelText='取消'
                    destroyOnClose={true}
                >
                    <Form ref={this.formRef} initialValues={{ newName: this.state.oldName }}>
                        <FormItem
                            label='企业名称'
                            name='newName'
                            rules={[{ required: true, message: '请输入企业名称!' }] }
                        >
                            <Input placeholder="请输入企业名称" />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default ManageCompany